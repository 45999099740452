import React from "react";
import {Grid, Typography, Box} from "@mui/material";
import {styled} from "@mui/system";
import {ContainerFluid, ContainerFluidLgPaddingTop, LgPaddingTop} from "./Home";

export const LogoBox = styled(Box)(({theme}) => ({
    display: "flex",
    padding: 10,
    marginTop: 8,
    marginRight: 8,
    height: 45,
    borderRadius: 20,
    textAlign: "center",
    backgroundColor: "#fff"
}));

function IntegrationSection() {
    return (
        <LgPaddingTop sx={{backgroundColor: "#FFFFFF"}}>
            <ContainerFluidLgPaddingTop>
                <Typography variant="h3" align="center" sx={{paddingBottom: 3}}>
                    Use the Power of Integrations
                </Typography>
                <Typography align="center" sx={{paddingBottom: "60px", color: "#555", fontSize: 24}}>
                    Feed your traffic source AI, automate cost and revenue tracking, optimize ad campaigns through
                    the Skro UI.
                </Typography>
                <ContainerFluid sx={{paddingLeft: 1, paddingRight: 1}}>
                    <Grid container spacing={3}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            data-aos="zoom-in-up"
                        >
                            <Box display="flex" flexDirection="column"
                                 sx={{backgroundColor: "#f9f9f9", padding: "16px 16px", borderRadius: 4}}
                                 justifyContent="space-between" height="100%">
                                <Box mt={1} mb={4}>
                                    <Typography variant="h2" align="left" sx={{
                                        fontSize: {xs: 18, sm: 18, md: 24, lg: 24},
                                        fontWeight: 600,
                                        color: "primary.main",
                                    }}>
                                        Optimize Ads
                                    </Typography>
                                </Box>
                                <Box mb={3} flex={1}>
                                    <Typography variant="body1" color="textSecondary" align="justify">
                                        Connect all your ad accounts and manage your campaigns from one
                                        easy-to-use platform. No more hopping between dashboards or guessing what’s
                                        working. Skro gives you the data-driven insights and automation you need to
                                        optimize smarter, scale faster, and maximize your ad performance across multiple
                                        networks with ease.
                                    </Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" flexWrap="wrap">
                                    <LogoBox>
                                        <img src="images/integration/meta.svg" alt="Facebook" width="85"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/tiktok.svg" alt="TikTok" width="100"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/google_ads.svg" alt="Google Ads" width="105"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/microsoftads.svg" alt="Microsoft Ads (Bing)"
                                             width="90"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/taboola.svg" alt="Taboola" width="80"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/outbrain.svg" alt="Outbrain" width="100"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/revcontent.svg" alt="Revcontent" width="110"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/mgid.svg" alt="MGID" width="70"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/propellerads.svg" alt="PropellerAds" width="125"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/exoclick.svg" alt="ExoClick" width="90"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/pushground.svg" alt="Pushground" width="110"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/partners/mondiad.svg" alt="Mondiad" width="95"/>
                                    </LogoBox>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            data-aos="zoom-in-up"
                        >
                            <Box display="flex" flexDirection="column"
                                 sx={{backgroundColor: "#f9f9f9", padding: "16px 16px", borderRadius: 4}}
                                 justifyContent="space-between" height="100%">
                                <Box mt={1} mb={4}>
                                    <Typography variant="h2" align="left" sx={{
                                        fontSize: {xs: 18, sm: 18, md: 24, lg: 24},
                                        fontWeight: 600,
                                        color: "primary.main",
                                    }}>
                                        Cost Sync
                                    </Typography>
                                </Box>
                                <Box mb={3} flex={1}>
                                    <Typography variant="body1" color="textSecondary" align="justify">
                                        You can easily monitor the expenses associated with your paid advertising
                                        campaigns at every level by using Skro. All you need to do is connect one or
                                        more of your ad network accounts that Skro supports, and the tool will
                                        automatically synchronize your cost data every 30 minutes. With this feature,
                                        you can stay up-to-date on your campaign spending and gain a comprehensive
                                        understanding of your advertising expenses across various ad networks and
                                        campaigns.
                                    </Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" flexWrap="wrap">
                                    <LogoBox>
                                        <img src="images/integration/meta.svg" alt="Facebook" width="85"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/tiktok.svg" alt="TikTok" width="100"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/google_ads.svg" alt="Google Ads" width="105"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/microsoftads.svg" alt="Microsoft Ads (Bing)"
                                             width="90"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/taboola.svg" alt="Taboola" width="80"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/outbrain.svg" alt="Outbrain" width="100"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/revcontent.svg" alt="Revcontent" width="110"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/mgid.svg" alt="MGID" width="70"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/propellerads.svg" alt="PropellerAds" width="125"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/exoclick.svg" alt="ExoClick" width="90"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/pushground.svg" alt="Pushground" width="110"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/partners/mondiad.svg" alt="Mondiad" width="95"/>
                                    </LogoBox>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            data-aos="zoom-in-up"
                        >
                            <Box display="flex" flexDirection="column"
                                 sx={{backgroundColor: "#f9f9f9", padding: "16px 16px", borderRadius: 4}}
                                 justifyContent="space-between" height="100%">
                                <Box mt={1} mb={4}>
                                    <Typography variant="h2" align="left" sx={{
                                        fontSize: {xs: 18, sm: 18, md: 24, lg: 24},
                                        fontWeight: 600,
                                        color: "primary.main",
                                    }}>
                                        Conversion API
                                    </Typography>
                                </Box>
                                <Box mb={3} flex={1}>
                                    <Typography variant="body1" color="textSecondary" align="justify">
                                        You can now avoid issues related to post-GDPR, iOS 14, or cookie-based
                                        attribution by using an API to send precise conversion data. This means that
                                        you can ensure accurate measurement and tracking of your conversions without
                                        being affected by changes in regulations or restrictions on cookie-based
                                        tracking. With this feature, you can have confidence in your data and make
                                        informed decisions based on accurate conversion tracking.
                                    </Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" flexWrap="wrap">
                                    <LogoBox>
                                        <img src="images/integration/meta.svg" alt="Facebook" width="85"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/tiktok.svg" alt="TikTok" width="100"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/google_ads.svg" alt="Google Ads" width="105"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/microsoftads.svg" alt="Bing" width="90"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/taboola.svg" alt="Taboola" width="80"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/outbrain.svg" alt="Outbrain" width="100"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/revcontent.svg" alt="Revcontent" width="110"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/mgid.svg" alt="MGID" width="70"/>
                                    </LogoBox>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            data-aos="zoom-in-up"
                        >
                            <Box display="flex" flexDirection="column"
                                 sx={{backgroundColor: "#f9f9f9", padding: "16px 16px", borderRadius: 4}}
                                 justifyContent="space-between" height="100%">
                                <Box mt={1} mb={4}>
                                    <Typography variant="h2" align="left" sx={{
                                        fontSize: {xs: 18, sm: 18, md: 24, lg: 24},
                                        fontWeight: 600,
                                        color: "primary.main",
                                    }}>
                                        Revenue Sync (AFD & RSOC)
                                    </Typography>
                                </Box>
                                <Box mb={3} flex={1}>
                                    <Typography variant="body1" color="textSecondary" align="justify">
                                        No more manual uploads or wasted time. Easily connect your preferred search
                                        feed arbitrage or affiliate network and get instant visibility into your
                                        earnings while Skro handles the data for you.
                                    </Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" flexWrap="wrap">
                                    <LogoBox>
                                        <img src="images/integration/clickbank.svg" alt="ClickBank" width="100"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/sedo.svg" alt="Sedo" width="100"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/system1.svg" alt="System1" width="90"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/tonic.svg" alt="Tonic" width="90"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/bodis.svg" alt="Bodis" width="90"/>
                                    </LogoBox>
                                    <LogoBox sx={{padding: "6px"}}>
                                        <img src="images/integration/ads.svg" alt="Ads.com" width="120"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/domain_active.svg" alt="Domain Active"
                                             width="120"/>
                                    </LogoBox>
                                    <LogoBox sx={{padding: "2px"}}>
                                        <img src="images/integration/ask-media-group.svg" alt="Ask Media Group"
                                             width="120"/>
                                    </LogoBox>
                                    <LogoBox sx={{padding: "16px"}}>
                                        <img src="images/integration/predicto.png" alt="Predicto.ai" width="100"/>
                                    </LogoBox>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            data-aos="zoom-in-up"
                        >
                            <Box display="flex" flexDirection="column"
                                 sx={{backgroundColor: "#f9f9f9", padding: "16px 16px", borderRadius: 4}}
                                 justifyContent="space-between" height="100%">
                                <Box mt={1} mb={4}>
                                    <Typography variant="h2" align="left" sx={{
                                        fontSize: {xs: 18, sm: 18, md: 24, lg: 24},
                                        fontWeight: 600,
                                        color: "primary.main",
                                    }}>
                                        Ringba and Retreaver Call Tracking
                                    </Typography>
                                </Box>
                                <Box mb={3} flex={1}>
                                    <Typography variant="body1" color="textSecondary" align="justify">
                                        Skro integrates with Retreaver and Ringba, allowing you to track and optimize
                                        your pay-per-call marketing campaigns from a single dashboard.
                                    </Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" flexWrap="wrap">
                                    <LogoBox>
                                        <img src="images/integration/retreaver.svg" alt="Retreaver" width="100"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/ringba.svg" alt="Ringba" width="100"/>
                                    </LogoBox>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            data-aos="zoom-in-up"
                        >
                            <Box display="flex" flexDirection="column"
                                 sx={{backgroundColor: "#f9f9f9", padding: "16px 16px", borderRadius: 4}}
                                 justifyContent="space-between" height="100%">
                                <Box mt={1} mb={4}>
                                    <Typography variant="h2" align="left" sx={{
                                        fontSize: {xs: 18, sm: 18, md: 24, lg: 24},
                                        fontWeight: 600,
                                        color: "primary.main",
                                    }}>
                                        Deploy Landers on AWS
                                    </Typography>
                                </Box>
                                <Box mb={3} flex={1}>
                                    <Typography variant="body1" color="textSecondary" align="justify">
                                        Our platform takes the complexity out of managing domains, SSL certificates,
                                        website deployments and distributions. From automatic domain verification and
                                        SSL generation to one-click deployments and distributions. We provide everything
                                        you need to get your site live and secure.
                                    </Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" flexWrap="wrap">
                                    <LogoBox>
                                        <img src="images/integration/aws.svg" alt="AWS" width="90"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/cloudflare.svg" alt="Cloudflare" width="80"/>
                                    </LogoBox>
                                    <LogoBox>
                                        <img src="images/integration/namesilo.svg" alt="NameSilo" width="110"/>
                                    </LogoBox>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </ContainerFluid>
            </ContainerFluidLgPaddingTop>
        </LgPaddingTop>
    );
}

export default IntegrationSection;
