import React from "react";
import {Alert, Box} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocDivider, DocH2, DocImage, DocRoot} from "../Docs";

function BuyGoodsIntegration() {
    return (
        <DocRoot>
            <Box style={{textAlign: "center", paddingBottom: "12px"}}>
                <a href="https://buygoods.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/buygoods.png" alt="BuyGoods Logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="BuyGoods and Skro"/>
            <Box>
                <p>
                    <a href="https://buygoods.com" target="_blank" rel="noreferrer">
                        BuyGoods
                    </a> is a global Internet Retailer, offering safe and reliable online shopping experience focused on
                    customer's experience and service.
                </p>
                <p>
                    Integration gives you the ability to track sales, upsell and initiate checkout events
                    and send postback back to your traffic source.
                </p>

                <DocH2>1. Add custom conversions to Skro</DocH2>
                <DocDivider/>
                <p>1. Go to Settings -> Custom Conversions -> Create</p>
                <DocImage src="/images/docs/buygoods-int-1.jpg" alt="Add custom conversions"/>

                <p>2. Create event named <strong>Initiate Checkout</strong></p>
                <DocImage src="/images/docs/buygoods-int-2.jpg" alt="Initiate Checkout event"/>

                <p>3. Copy the Postback pixel for <strong>Initiate Checkout</strong>.</p>
                <p>4. Log in to your BuyGoods account and go to Settings -> My Profile -> Funnel pixels.</p>
                <p>5. Paste code to <strong>Checkout</strong> field.</p>

                <Alert severity="info" color="success" variant="filled">
                    {`<script async type="text/javascript"
                             src="https://skrotrack.com/postback?clickId={SUBID}&ce=InitiateCheckout"></script>`}
                </Alert>

                <Alert severity="warning" color="warning" variant="filled">If you are using your own custom domain, then
                    please replace skrotrack.com</Alert>

                <DocImage src="/images/docs/buygoods-int-7.jpg" alt="Initiate Checkout event"/>

                <DocH2>Step 2. Add BuyGoods affiliate network to Skro</DocH2>
                <DocDivider/>
                <p>1. Go to Affiliate Networks -> Create -> Find BuyGoods logo and click on it.</p>
                <p>2. Select workspace.</p>
                <p>3. Copy postback URL.</p>
                <p>4. Click <strong>Save & Close</strong></p>
                <DocImage src="/images/docs/buygoods-int-4.jpg" alt="Add affiliate network"/>

                <p>5. Log in to Buygoods -> Settings -> Postback Pixels -> New</p>
                <p>6. Paste postback URL to BuyGoods.</p>
                <Alert severity="info" color="success" variant="filled">
                    {`https://skrotrack.com/postback?clickId={SUBID}&payout={COMMISSION_AMOUNT}&transactionId={ORDERID}`}
                </Alert>
                <DocImage src="/images/docs/buygoods-int-6.jpg" alt="Add postback pixel"/>

                <DocH2>Step 3. Add BuyGoods Offer to Skro</DocH2>
                <DocDivider/>
                <p>
                    1. Go to <strong>Offers</strong> -> <strong>Create</strong>
                </p>
                <p>2. Choose your workspace.</p>
                <p>3. Add name.</p>
                <p>
                    4. Grab the offer link from BuyGoods and add it to offer URL field and
                    append <strong>{`&subid={clickId}`}</strong> to the end of your offer URL.
                </p>
                <p>5. Choose BuyGoods Affiliate Network.</p>
                <DocImage src="/images/docs/buygoods-int-5.jpg" alt="Add offer"/>

                <DocH2>Step 4. Create campaign</DocH2>
                <DocDivider/>
                <p>1. Go to Campaigns -> Create.</p>
                <p>2. Fill all the fields where needed.</p>
                <p>3. On the final step you will get tracking link for your traffic source.</p>

            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default BuyGoodsIntegration;
