import React from "react";
import {Alert, Box} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocDivider, DocH2, DocImage, DocRoot} from "../Docs";

function RsocAdsComIntegration() {
    return (
        <DocRoot>
            <Box sx={{textAlign: "center"}}>
                <a href="https://ads.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 100}} src="/images/integration/ads.svg" alt="Ads.com logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="RSOC Ads.com and Skro"/>
            <Box>
                <DocH2>Who we are</DocH2>
                <p>
                    <a href="https://ads.com" target="_blank" rel="noreferrer">
                        Ads.com
                    </a> is a monetization platform committed to delivering innovative, highly competitive,
                    scalable advertising solutions that guarantee superior integration rates for both monetizers and
                    advertisers alike. With over decades of combined experience in the digital advertising
                    space/industry, our team is dedicated to making Ads.com the web’s leading advertising application.
                </p>
                <h2>Integration benefits</h2>
                <p>
                    Integration enables seamless revenue data synchronization across channels, ensuring accurate
                    tracking even after adjustments from various traffic sources. It also consolidates your ads,
                    conversions, and revenue, giving you a clear and comprehensive view of your ad performance.
                </p>
                <ul>
                    <li>Revenue updates every hour.</li>
                    <li>Revenue updates for the last 3 days.</li>
                </ul>

                <DocH2>Step 1. Add integration</DocH2>
                <DocDivider/>
                <p>1. Log in to your <strong>Skro</strong> account.</p>
                <p>2. Navigate to <strong>Settings</strong> -> <strong>Integrations</strong>.</p>
                <p>
                    3. Scroll down and click on the <strong>Add</strong> button in <strong>Revenue
                    Sync - RSOC</strong> section.
                </p>
                <DocImage src="/images/docs/rsocadscom/rsoc-ads-com-1.png" alt="Ads.com RSOC integration step 1"
                />
                <p>4. Click on the <strong>Ads.com</strong> logo.</p>
                <DocImage src="/images/docs/rsocadscom/rsoc-ads-com-2.png" alt="Ads.com RSOC integration step 2"
                />
                <ul>
                    <li><strong>Integration Name</strong> name of your integration.</li>
                    <li>
                        <strong>Domain Name</strong> is your offer domain name provided by Ads.com.
                    </li>
                    <li>
                        <strong>Username</strong> is your Ads.com account username.
                    </li>
                    <li>
                        <strong>Password</strong> is your Ads.com account password.
                    </li>
                    <li>
                        <strong>API Secret</strong> is your API secret key. You will find it on Ads.com if you
                        go to <strong>Tracking</strong> -> <strong>API</strong> -> <strong>Your Secret Key</strong>.
                    </li>
                    <li><strong>Account Currency</strong> is the currency of the earnings.</li>
                    <li>
                        <strong>Custom Conversion Event</strong> is not required field. If you are using Custom
                        Conversion on your postback URL, then you will need to select your event here, otherwise your
                        revenue isn't synced correctly.
                    </li>
                </ul>
                <DocImage src="/images/docs/rsocadscom/rsoc-ads-com-3.png" alt="Ads.com RSOC integration step 3"
                />

                <p>5. Fill the form and click <strong>Save & Close</strong></p>

                <DocH2>Step 2. Add Ads.com RSOC offer in Skro</DocH2>
                <DocDivider/>

                <p>1. Go to <strong>Offers</strong> page.</p>
                <p>2. Click on <strong>Create</strong> button and fill fields where needed.</p>
                <p>
                    3. Map your <strong>RSOC Channel/Campaign ID</strong> on special field to track revenue
                    correctly.
                </p>

                <DocImage src="/images/docs/rsocadscom/rsoc-ads-com-5.png" alt="Ads.com RSOC integration step 4"
                          sx={{mb: 3}}/>

                <p>This is an example offer URL which goes to Skro:</p>
                <Alert severity="success" variant="filled" color="success">
                    {`https://example.com/?subid2={clickId}&campaign_id=123456789`}
                </Alert>

                <p>
                    Offer URL has 2 important parameters:
                </p>
                <p>
                    1. <strong>&campaign_id=123456789</strong> - is a Ads.com Channel/Campaign ID.
                </p>
                <p>
                    2. <strong>{`&subid2={clickId}`}</strong> - is a Skro Click ID to track conversions with postback
                    URL.
                </p>

                <Alert color="warning" variant="filled" severity="warning" sx={{mt: 2, mb: 2}}>
                    {`Note: If you are encoding Offer URL, do not encode Skro click id macro {clickId}`}
                </Alert>

                <DocH2>Step 3. Standard Postback URL for conversions</DocH2>
                <DocDivider/>
                <p>
                    1. Go to <strong>Affiliate Networks</strong> and click on <strong>Create</strong> button.
                </p>
                <p>
                    2. Search <strong>Ads.com</strong> and click on <strong>Ads.com logo</strong>.
                </p>
                <p>
                    3. Copy the postback URL.
                </p>
                <p>
                    4. Switch on <strong>Allow double conversions</strong>.
                </p>
                <p>5. If you are done then click on <strong>Save & Close</strong> button.</p>
                <DocImage src="/images/docs/rsocadscom/rsoc-ads-com-4.png" alt="Ads.com RSOC integration step 5"
                          sx={{mb: 1}}/>

                <p>
                    4. Go to <strong>Ads.com</strong> and naivigate to
                    the <strong>Tracking</strong> -> <strong>Postback</strong> and click on <strong>Create New
                    S2S</strong> button.
                </p>
                <p>5. Fill all the fields</p>
                <ul>
                    <li><strong>Event ID:</strong> Click</li>
                    <li><strong>Event Type:</strong> Ad Click</li>
                    <li><strong>Postback URL:</strong> {`https://skrotrack.com/postback?clickId={subid2}&p1={click_term}`}</li>
                </ul>
                <p>6. If you are done, then click on <strong>ADD S2S</strong> button.</p>
                <DocImage src="/images/docs/rsocadscom/rsoc-ads-com-6.png" alt="Ads.com RSOC integration step 5"
                          sx={{mb: 3}}/>

                <DocH2>Step 4. Use Custom Conversions for different Ads.com events (OPTIONAL)</DocH2>
                <DocDivider/>
                <p>
                    With custom conversion events feature it is possible to track different events, revenue and clicks
                    on a different columns. For Ads.com we are going to create event <strong>Purchase</strong>.
                </p>
                <p>1. Go back to Skro and navigate to the <strong>Settings</strong> -> <strong>Custom
                    Conversions</strong> and click on <strong>Create</strong> button.</p>
                <p>
                    2. Fill all the fields and click on <strong>Save & Close</strong> button.
                </p>
                <DocImage src="/images/docs/rsocadscom/rsoc-ads-com-7.png" alt="Ads.com RSOC event purchase"/>

                <p>
                    3. Go to <strong>Ads.com</strong> and naivigate to
                    the <strong>Tracking</strong> -> <strong>Postback</strong> and click on <strong>Create New
                    S2S</strong> button.
                </p>
                <p>4. Fill all the fields</p>
                <ul>
                    <li><strong>Event ID:</strong> Click</li>
                    <li><strong>Event Type:</strong> Ad Click</li>
                    <li><strong>Postback URL:</strong> {`https://skrotrack.com/postback?clickId={subid2}&p1={click_term}&ce=Purchase`}
                    </li>
                </ul>
                <p>5. If you are done, then click on <strong>ADD S2S</strong> button.</p>
                <DocImage src="/images/docs/rsocadscom/rsoc-ads-com-8.png" alt="Ads.com RSOC integration step 8"
                          sx={{mb: 3}}/>

                <DocDivider/>
                <p>Integration setup is done and your are ready to sync your earnings data.</p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default RsocAdsComIntegration;
