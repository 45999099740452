import React from "react";
import {Alert, Box, Divider} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";

function RevcontentIntegration() {
    return (
        <DocRoot>
            <Box sx={{textAlign: "center"}} pb={2} pt={2}>
                <a href="https://revcontent.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/revcontent.svg" alt="Revcontent Logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to integrate Revcontent with Skro?"/>
            <Box>
                <p>
                    <a href="https://revcontent.com" target="_blank" rel="noreferrer">
                        Revcontent
                    </a> is a content marketing and native advertising platform that helps brands reach their audience
                    through engaging content. It offers a network of high-quality publishers and uses advanced targeting
                    tools to deliver relevant ads that drive conversions.
                </p>
                <p>
                    Revcontent integration gives you the ability to manage and optimize all your Revcontent ad campaigns
                    through the Skro interface.
                </p>
                <ul>
                    <li>Sync cost for campaigns, widgets and ads every 30 min.</li>
                    <li>Check the status of your campaigns, widgets and ads.</li>
                    <li>Pause or resume campaigns, widgets and ads.</li>
                    <li>Change bidding for campaigns and widgets.</li>
                    <li>Change budget for campaigns.</li>
                </ul>
                <p>
                    In this guide, you will learn how to set up your campaigns in Revcontent and Skro so Revcontent
                    can pass information to Skro and back with a selected tracking method. This guide does not cover all
                    aspects and options available when creating a campaign on these two platforms. Basic knowledge
                    regarding setting up campaigns in Revcontent and Skro is required.
                </p>
                <h2>Optimize Revcontent ads through Skro</h2>
                <ul>
                    <li>Go to campaigns view and double-click on your campaign name.</li>
                    <li>
                        On the campaign details view select from the first <strong>Group By</strong> dropdown
                        menu <strong>"C3: Campaign ID"</strong>
                    </li>
                    <li>
                        If you want to optimize widgets, then select on second <strong>Group By</strong> dropdown
                        menu <strong>"C1: Widget ID"</strong>
                    </li>
                    <li>
                        If you want to optimize contents, then select on second <strong>Group By</strong> dropdown
                        menu <strong>"C2: Content ID"</strong>
                    </li>
                </ul>
                <p><strong>Manage Revcontent campaigns</strong></p>
                <DocImage src="/images/docs/revcontent/rc-optimize-1.png" alt="Manager Revcontent campaigns"
                />

                <p><strong>Manage Revcontent widgets</strong></p>
                <DocImage src="/images/docs/revcontent/rc-optimize-2.png" alt="Manager Revcontent widgets"
                />

                <p><strong>Manage Revcontent contents</strong></p>
                <DocImage src="/images/docs/revcontent/rc-optimize-3.png" alt="Manager Revcontent contents"
                />
                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>I. Get Revcontent API Credentials</h2>
                <p>
                    You can get API credentials by following the steps below:
                </p>
                <ul>
                    <li>
                        In Revcontent, click on the username on the top right corner and navigate to
                        the <strong>Account settings</strong>.
                    </li>
                </ul>
                <DocImage src="/images/docs/revcontent/rc-integration-1.png" alt="Get Revcontent api credentials 1"
                />
                <ul>
                    <li>
                        Scroll down to the section <strong>Stats API Credentials</strong>. If you do not have API
                        credentials there then please ask Revcontent to activate your API access.
                    </li>
                    <li>
                        Copy the <strong>Client ID</strong> and <strong>Client Secret</strong> to any text editing tool.
                    </li>
                </ul>
                <DocImage src="/images/docs/revcontent/rc-integration-2.png" alt="Get Revcontent api credentials 2"
                />
                <p>
                    Once you have everything ready, read the further part of this guide.
                </p>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>II. Setup integration</h2>
                <ul>
                    <li>Log in to your <strong>Skro</strong> account.</li>
                    <li>Navigate to <strong>Settings -> Integrations</strong>.</li>
                    <li>In the <strong>Traffic Sources</strong> section click on <strong>Add</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/revcontent/rc-integration-3.png" alt="Revcontent Integration step 1"
                />
                <ul>
                    <li>Click on <strong>Revcontent</strong> logo and integration form will pop up.</li>
                </ul>
                <DocImage src="/images/docs/revcontent/rc-integration-4.png" alt="Revcontent Integration step 2"
                />
                <ul>
                    <li>
                        Enter your <strong>Integration Name</strong>.
                    </li>
                    <li>
                        Enter your Revcontent <strong>Client ID</strong>.
                    </li>
                    <li>
                        Enter your Revcontent <strong>Client Secret</strong>.
                    </li>
                    <li>Click on <strong>Create Integration</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/revcontent/rc-integration-5.png" alt="Revcontent Integration step 3"
                />
                <ul>
                    <li>Your Revcontent account is successfully integrated.</li>
                    <li>You can switch on/off cost sync with given switch button.</li>
                </ul>
                <DocImage src="/images/docs/ob-integration-6.png" alt="Revcontent Integration step 4"
                />

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>III. Associate the integration with your traffic source.</h2>
                <ul>
                    <li>
                        Navigate to the <strong>Traffic Sources</strong> and click on <strong>Create</strong> button.
                    </li>
                </ul>
                <DocImage src="/images/docs/ob-integration-7.png" alt="Setup Revcontent traffic source Step 1"
                />
                <ul>
                    <li>Find <strong>Revcontent</strong> logo and click on it.</li>
                </ul>
                <DocImage src="/images/docs/revcontent/rc-traffic-1.png" alt="Setup Revcontent traffic source step 2"/>
                <ul>
                    <li>Setup your <strong>Postback URL</strong>.</li>
                </ul>
                <DocImage src="/images/docs/revcontent/rc-traffic-2.png" alt="Setup Revcontent traffic source step 3"/>
                <ul>
                    <li>Replace placeholder <strong>REPLACE</strong> with your Revcontent API key.</li>
                    <li>
                        Get API key from Revcontent. Click on the username on the top right corner and
                        choose <strong>Account Settings</strong>.
                    </li>
                    <li>Scroll down to the <strong>API key</strong> section.</li>
                    <li>Copy the key and replace the placeholder on Skro postback URL.</li>
                </ul>
                <DocImage src="/images/docs/revcontent/rc-traffic-4.png" alt="Setup Revcontent traffic source step 4"/>
                <ul>
                    <li>Select your Revcontent integration from the dropdown menu.</li>
                </ul>
                <DocImage src="/images/docs/revcontent/rc-traffic-3.png" alt="Setup Revcontent traffic source step 5"/>
                <ul>
                    <li>All parameters are already pre-filled for you.</li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>

                <p>Integration setup is done and your are ready to create your first campaign for Revcontent.</p>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>IV. Create a Campaign in Skro</h2>
                <p>
                    If your setup requires pre-lander then add it like that.
                </p>

                <ul>
                    <li>Navigate to the <strong>Landings</strong> view and click on <strong>Create</strong> button.</li>
                    <li>Select workspace.</li>
                    <li>Add name.</li>
                    <li>Add your landing URL and add macros if needed.</li>
                    <li>
                        Copy the <strong>Click to offer URL</strong> and paste it to your pre-lander which redirects
                        you to the offer page.
                    </li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/ob-skro-campaign-0.png" alt="Setup Skro Landing"
                />

                <p>
                    The second thing you need to do is to create an offer. Offer can be your own website/product or
                    affiliate network offer.
                </p>
                <ul>
                    <li>Navigate to the <strong>Offers</strong> view and click on <strong>Create</strong> button.</li>
                    <li>Select workspace.</li>
                    <li>Add name.</li>
                    <li>
                        Add your offer URL and add macros. Don't forget to add Skro Click ID macro to your offer URL
                        with correct parameter name.
                    </li>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/google-offer-1.png" alt="Setup Skro Offer"/>
                <p>Example of offer URL with Skro click id macro.</p>
                <Alert severity="warning" variant="filled" color="warning" style={{marginBottom: 8}}>
                    {`https://example.com/offer/?s2={clickId}`}
                </Alert>
                <ul>
                    <li>
                        Navigate to the <strong>Campaigns</strong> view and click on <strong>Create</strong> button.
                    </li>
                    <li>Select your integrated <strong>Traffic Source</strong> as Revcontent.</li>
                    <li>Cost Model should be <strong>Auto</strong>.</li>
                    <li>Currency should be <strong>USD</strong>.</li>
                    <li>Click on <strong>Next</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/revcontent/rc-campaign-1.png" alt="Setup Skro Campaign"
                />
                <ul>
                    <li>
                        Select workspace.
                    </li>
                    <li>Add descriptive name to your campaign.</li>
                    <li>Click <strong>Next</strong></li>
                </ul>
                <DocImage src="/images/docs/revcontent/rc-campaign-2.png" alt="Setup Skro Campaign"
                />
                <ul>
                    <li>Choose your <strong>Destination Type</strong>. In this example we use <strong>flow</strong>.
                    </li>
                    <li>Choose your redirect mode. In this example we use <strong>redirect 302</strong>.</li>
                    <li>Choose your landing page.</li>
                </ul>
                <Alert severity="warning" variant="filled" color="warning" style={{marginBottom: 8}}>
                    In given example we use one landing and one offer setup.
                </Alert>
                <ul>
                    <li>Click <strong>Save</strong> when you are done.</li>
                </ul>
                <DocImage src="/images/docs/revcontent/rc-campaign-3.png" alt="Setup Skro Campaign"
                />
                <ul>
                    <li>Choose the <b>Redirect</b> method.</li>
                    <li>
                        If you didn't install the <strong>Click to Offer URL</strong> on your pre-lander, then do it
                        now.
                        In given example the link is <strong>https://skrotrack.com/click</strong>
                    </li>
                </ul>
                <Alert severity="info" variant="filled" color="info" style={{marginBottom: 8}}>
                    In given example we have that kind of Skro Campaign Tracking Link:<br/><br/>
                    {"https://skrotrack.com/campaign/438b7f75-d001-479b-bc15-4e9059c9fbb6?rc_uuid=&widget_id={widget_id}&content_id={content_id}&boost_id={boost_id}&adv_targets={adv_targets}"}
                </Alert>
                <DocImage src="/images/docs/revcontent/rc-campaign-4.png" alt="Setup Skro Campaign"/>
                <ul>
                    <li>Copy the link and paste it to Revcontent and start tracking.</li>
                </ul>

                <Divider sx={{mt: 3, mb: 2}}/>
                <h2>V. Create a Campaign in Revcontent</h2>
                <p>
                    Now you have your campaign URL with tracking parameters added. In the next step you will submit it
                    as your item's URL in Revcontent campaign setup. In order to do that, perform the following
                    steps:
                </p>
                <ul>
                    <li>Sign in to your Revcontent account.</li>
                    <li>Go to the <strong>Campaigns</strong> view.</li>
                    <li>
                        Click the <strong>Create Campaign</strong> button and setup your campaign.
                    </li>
                </ul>
                <DocImage src="/images/docs/revcontent/rc-campaign-5.png" alt="Setup Revcontent Campaign"/>
                <ul>
                    <li>Setup your audience and budgets.</li>
                    <li>Click on <strong>Save</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/revcontent/rc-campaign-6.png" alt="Setup Revcontent Campaign"/>
                <ul>
                    <li>Set up your contents.</li>
                    <li>
                        On the <strong>Destination URL</strong> goes your Skro Campaign Tracking link with params and
                        macros.
                    </li>
                    <li>Click on <strong>Submit</strong>.</li>
                </ul>
                <p>This is your campaign tracking link in this example.</p>
                <Alert severity="warning" variant="filled" color="warning" style={{marginBottom: 8}}>
                    {`https://skrotrack.com/campaign/438b7f75-d001-479b-bc15-4e9059c9fbb6?rc_uuid=&widget_id={widget_id}&content_id={content_id}&boost_id={boost_id}&adv_targets={adv_targets}`}
                </Alert>
                <DocImage src="/images/docs/revcontent/rc-campaign-7.png" alt="Setup Revcontent Campaign"/>
                <ul>
                    <li>
                        Once your set up is done, you will need to wait when your ads are reviewed and approved.
                    </li>
                </ul>

                <p>
                    Congratulations, you have successfully set up tracking of Revcontent traffic with Skro. Each time a
                    visitor clicks on your ad, will be directed to your landing page with Skro's tracking parameters
                    being passed.
                </p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default RevcontentIntegration;
