import React from "react";
import {Alert, Box} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocDivider, DocH2, DocImage, DocRoot} from "../Docs";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";

function RingbaIntegration() {
    const ringbaScript = `
        <script>
            (window._rgba_tags = (window._rgba_tags || [])).push({type: "User", track_attempted: "yes"});
            var intervalId = setInterval(() => {
                if (window.skclid !== undefined) {
                    (window._rgba_tags = (window._rgba_tags || [])).push({type: "User", skro_click_id: window.skclid});
                    console.log("Add Skro Click ID to Ringba: ", window.skclid);
                    clearInterval(intervalId);
                } else {
                    console.log("Waiting skclid value...");
                }
            }, 500);
        </script>
    `;

    const universalScriptWithRingbaTag = `
    <html>
        <head>
            <script type="text/javascript">!function () {
                const config = {
                    organicCampaignToken: "",
                    customDomain: "skrotrack.com",
                    firstClickAttribution: false,
                    cookieName: "skro-click-id",
                    campaignParameterName: "trck",
                    httpProtocol: "https",
                    cookieExpTime: 30 * 24 * 60 * 60 * 1000
                };
                let skroCookie = getCookie(config.cookieName);
                let sessionClickID = sessionStorage.getItem(config.cookieName);
                let URLParams = new URLSearchParams(window.location.search);
                let campaignID = URLParams.get(config.campaignParameterName);
                let skroClickId = URLParams.get("skrocid");
                let pixelParams = removeParam(config.campaignParameterName, window.location.search);
                if (skroClickId !== null) {
                    if (!sessionClickID) {
                        if (skroCookie === null || skroCookie === undefined || skroCookie === 'undefined' || !config.firstClickAttribution) {
                            setCookie(config, skroClickId);
                        }
                        setSessionClickId(config, skroClickId);
                    }
                    return false;
                }
                if (sessionClickID !== null && sessionClickID !== undefined) {
                    window.skclid = sessionClickID;
                }
                if (!campaignID || campaignID === 'undefined' || campaignID === "") {
                    campaignID = config.organicCampaignToken;
                }
                if (!sessionClickID && campaignID !== "") {
                    let xhr = new XMLHttpRequest();
                    xhr.open("GET", config.httpProtocol + "://" + config.customDomain + "/offer?trck=" + campaignID + "&" + pixelParams);
                    xhr.send();
                    xhr.onreadystatechange = function () {
                        if (xhr.readyState === 4 && xhr.status === 200) {
                            let resp = JSON.parse(xhr.response);
                            let clickId = resp?.clickId;
                            if (skroCookie === null || skroCookie === undefined || skroCookie === 'undefined' || !config.firstClickAttribution) {
                                setCookie(config, clickId);
                            }
                            setSessionClickId(config, clickId);
                        }
                    };
                }
        
                function setCookie(config, clickId) {
                    window.skclid = clickId;
                    let expirationTime = config.cookieExpTime, date = new Date(), dateTimeNow = date.getTime();
                    date.setTime(dateTimeNow + expirationTime);
                    document.cookie = config.cookieName + "=" + clickId + "; expires=" + date.toUTCString() + "; path=/; domain=." + location.hostname.replace(/^www\\./i, "");
                }
        
                function setSessionClickId(config, clickId) {
                    window.skclid = clickId;
                    sessionStorage.setItem(config.cookieName, clickId);
                }
        
                function getCookie(name) {
                    let value = "; " + document.cookie;
                    let parts = value.split("; " + name + "=");
                    if (parts.length === 2) return parts.pop().split(";").shift();
                }
        
                function removeParam(key, sourceURL) {
                    let rtn, param, params_arr = [],
                        queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
                    if (queryString !== "") {
                        params_arr = queryString.split("&");
                        for (let i = params_arr.length - 1; i >= 0; i -= 1) {
                            param = params_arr[i].split("=")[0];
                            if (param === key) {
                                params_arr.splice(i, 1);
                            }
                        }
                        rtn = params_arr.join("&");
                    }
                    return rtn;
                }
            }();</script>
        </head>
        <body>
        
        <script src=//b-js.ringba.com/CA91ba94034ecf44eea8c55da3f68dd400 async></script>
        
        </body>
    </html>
    `

    return (
        <DocRoot>
            <Box sx={{textAlign: "center"}}>
                <a href="https://ringba.com/" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 150}} src="/images/integration/ringba.svg" alt="Ringba logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="Ringba and Skro"/>
            <Box>
                <DocH2>Who we are</DocH2>
                <p>
                    <a href="https://ringba.com" target="_blank" rel="noreferrer">
                        Ringba
                    </a> is an inbound call tracking and analytics platform for marketers, brands, and pay per
                    call. They are helping digital agencies, pay per callers, and global brands drastically improve
                    their Return on Investment.
                </p>

                <DocH2>How do I track Ringba calls?</DocH2>
                <p>
                    In this guide you’ll learn how to track Ringba calls as conversions in Skro. Just follow
                    the steps below to start tracking your Ringba calls.
                </p>

                <DocH2>Step 1. Create a number pool in Ringba</DocH2>
                <DocDivider/>

                <p>If you already have a number pool you want to use, you can skip this step.</p>
                <p>
                    1. Go to <strong>Numbers</strong> -> <strong>Manage Pools</strong> -> <strong>Create Number
                    Pool</strong>.
                </p>
                <DocImage
                    src="/images/docs/ringba/int-1.png" alt="Create number pool" sx={{pt: 2, pb: 2}}
                />
                <p>
                    2. Then, add in the details for your number pool. If you’re getting a lot of traffic to your page at
                    the same time, you’ll want to have a higher Pool Size. This is so that each visitor can see a
                    different number and Ringba will be able to track which visitor called which number.
                </p>
                <DocImage
                    src="/images/docs/ringba/int-2.png" alt="Create a new campaign in Ringba" sx={{pt: 2, pb: 2}}
                />

                <DocH2>Step 2. Create a new campaign in Ringba</DocH2>
                <DocDivider/>

                <p>If you already have a campaign you want to track, you can skip this step.</p>
                <p>
                    1. Go to <strong>Campaigns</strong> -> <strong>Create Campaign</strong>.
                </p>
                <DocImage
                    src="/images/docs/ringba/int-3.png" alt="Create a new campaign in Ringba" sx={{pt: 2, pb: 2}}
                />

                <DocH2>Step 3. Create a call tracking tag and associate it with your publisher number</DocH2>
                <DocDivider/>

                <p>
                    Fill out all the details for your new campaign, then scroll down to <strong>Call Tracking
                    Tags</strong> and click on <strong>Add Call Tracking Tag</strong>.
                </p>

                <DocImage
                    src="/images/docs/ringba/int-4.png" alt="Create a call tracking tag in Ringba" sx={{pt: 2, pb: 2}}
                />

                <p>1. Give it a <strong>Name</strong>.</p>
                <p>2. Select the <strong>Primary Number</strong> you want this to be associated with.</p>
                <p>3. Switch on <strong>Capture User Data</strong>.</p>
                <p>4. Select the <strong>Number Pool</strong> you created earlier.</p>

                <p>
                    The Number to Replace must match the phone number on your landing page, so it can be replaced with a
                    different pool number for each visitor on your page. It is best if this matches the Primary Number.
                </p>

                <DocImage
                    src="/images/docs/ringba/int-5.png" alt="Fill call tracking tag form in Ringba" sx={{pt: 2, pb: 2}}
                />

                <p>
                    5. When <strong>Tag</strong> is created, then you will need to add the tag to your page. Click on
                    the <strong>icon</strong> show below to get the script.
                </p>
                <DocImage
                    src="/images/docs/ringba/int-6.png" alt="Fill call tracking tag form in Ringba" sx={{pt: 2, pb: 2}}
                />

                <p>
                    6. Then copy the script from <strong>Option 1</strong> and add it to your landing page,
                    between <strong>BODY</strong> tags.
                </p>
                <Alert color="warning" variant="filled" severity="warning" sx={{mt: 2, mb: 2}}>
                    If you are using <strong>Direct</strong> tracking method, then this script must go below your
                    Skro tracking pixel code. See the example code below.
                </Alert>

                <DocImage
                    src="/images/docs/ringba/int-7.png" alt="Copy tracking tag from Ringba" sx={{pt: 2, pb: 2}}
                />

                <SyntaxHighlighter language="javascript" customStyle={{fontSize: "12px"}}>
                    {universalScriptWithRingbaTag}
                </SyntaxHighlighter>

                <DocH2>Step 4. Create a URL parameter to collect Skro Click ID value</DocH2>
                <DocDivider/>
                <p>
                    1. Go to <strong>Integrations</strong> -> <strong>URL Parameters</strong> -> <strong>Create URL
                    Parameter</strong>.
                </p>
                <DocImage
                    src="/images/docs/ringba/int-8.png" alt="Create a url parameter in Ringba" sx={{pt: 2, pb: 2}}
                />
                <p>
                    2. Next, enter <strong>skro_click_id</strong> for both the URL Parameter and Report Name sections
                    and <strong>User</strong> for the Reporting Menu Name.
                </p>

                <DocImage
                    src="/images/docs/ringba/int-9.png" alt="Create a url parameter in Ringba" sx={{pt: 2, pb: 2}}
                />

                <Alert color="success" variant="outlined" severity="info" sx={{mt: 2, mb: 2}}>
                    <p>
                        {`– You can add multiple custom parameters to your Skro Offer URL, and all of them will be
                            captured by Ringba in Tags. Here is an example of an Offer URL with additional parameters and
                            Skro dynamic tokens: https://test.offer.com?skro_click_id={clickId}&country={country}&adName={custom1}`}
                    </p>

                    <p>
                        – Creating a new URL Parameter will automatically create a relevant dynamic
                        token <strong>[tag:User:skro_click_id]</strong> which can be used later for Skro S2S postback.
                    </p>

                    <p>
                        – Additionally, you can use this data for routing logic in the Ringba platform by
                        applying <a
                        href="https://support.ringba.com/en-us/article/tag-routing-filters-8ewcx/">
                        Tag Routing Filters
                    </a>.
                    </p>
                </Alert>

                <p>3. After this, we will need to associate the <strong>URL Parameter</strong> with your campaign in
                    Ringba. To do that, go to <strong>Campaigns</strong> -> <strong>select your
                        campaign</strong> -> <strong>scroll down to URL Parameters</strong>.
                </p>
                <p>
                    4. Click on <strong>Add URL Parameter</strong> -> <strong>Select Existing</strong> ->
                    select URL parameter <strong>skro_click_id</strong> -> click on <strong>Add</strong>.
                </p>

                <DocImage
                    src="/images/docs/ringba/int-10.png" alt="Associate url parameter in Ringba" sx={{pt: 2, pb: 2}}
                />

                <DocH2>Step 5. Add the following script to the body section of your landing page</DocH2>
                <DocDivider/>

                <p>
                    1. Copy and paste this script into <strong>BODY</strong> tags below Skro and Ringba scripts you
                    added earlier. This script collects the Skro Click ID and other params and sending them to Ringba.
                </p>

                <SyntaxHighlighter language="javascript" customStyle={{fontSize: "12px"}}>
                    {ringbaScript}
                </SyntaxHighlighter>

                <DocH2>Step 6. Add your Skro Postback URL to Ringba</DocH2>
                <DocDivider/>

                <p>
                    1. Go to <strong>Integrations</strong> -> <strong>Pixels</strong> -> <strong>Create Pixel</strong>.
                </p>

                <DocImage
                    src="/images/docs/ringba/int-11.png" alt="Create postback url in Ringba" sx={{pt: 2, pb: 2}}
                />

                <p>2. To track your <strong>Sale</strong> conversions in Skro, use this postback URL:</p>

                <Alert color="success" variant="filled" severity="success" sx={{mt: 2, mb: 2}}>
                    https://skrotrack.com/postback?clickId=[tag:User:skro_click_id]&payout=[Call:ConversionPayout]
                </Alert>

                <DocImage
                    src="/images/docs/ringba/int-12.png" alt="Place the skro postback url in Ringba" sx={{pt: 2, pb: 2}}
                />

                <DocDivider/>
                <p>
                    Integration is done. You are now ready to add your landing page as an <strong>Offer</strong> and
                    create your first <strong>Campaign</strong> in Skro.
                </p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default RingbaIntegration;
