import React from "react";
import {Box} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocDivider, DocH2, DocImage, DocRoot} from "../Docs";

function MaxWebIntegration() {
    return (
        <DocRoot>
            <Box style={{textAlign: "center", paddingBottom: "12px"}}>
                <a href="https://maxweb.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/maxweb.png" alt="MaxWeb Logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="MaxWeb and Skro"/>
            <Box>
                <p>
                    <a href="https://maxweb.com" target="_blank" rel="noreferrer">
                        MaxWeb
                    </a> is an affiliate network that operates in several verticals, such as nutra, ecommerce,
                    and survival. The following article describes how to set up tracking of their offers with Skro.
                </p>
                <p>
                    Integration gives you the ability to track sales and send postback to your traffic source.
                </p>

                <DocH2>Step 1. Add affiliate network to Skro</DocH2>
                <DocDivider/>
                <p>1. Go to Affiliate Networks and click on <strong></strong>Create button.</p>
                <DocImage src="/images/docs/maxweb-int-1.jpg" alt="Add affiliate network"/>

                <p>2. Search MaxWeb and click on MaxWeb logo.</p>
                <DocImage src="/images/docs/maxweb-int-2.jpg" alt="Choose MaxWeb prefilled template"/>

                <p>3. Copy postback pixel.</p>
                <p>4. Click <strong>Save & Close</strong>.</p>
                <DocImage src="/images/docs/maxweb-int-3.jpg" alt="Save MaxWeb as affiliate network"/>

                <p>
                    You have now the MaxWeb affiliate network added to Skro and configured postback URL copied in the
                    clipboard. Go to MaxWeb platform for the next step.
                </p>

                <DocH2>Step 2. Getting an offer from MaxWeb and setting up a postback URL.</DocH2>
                <DocDivider/>
                <p>1. In MaxWeb, go to the <strong>Campaigns</strong> view.</p>
                <p>
                    2. Locate an offer you want to promote. Note that you have to be approved before you will be able
                    to run some offers. Those offers will have the <strong>Apply to run</strong> button visible.
                    Offers that do not require approval will have the <strong>Promote</strong> button visible.
                </p>
                <DocImage src="/images/docs/maxweb-int-4.jpg" alt="View MaxWeb campaigns"/>

                <p>3. Click to enter your account.</p>
                <DocImage src="/images/docs/maxweb-int-5.jpg" alt="View MaxWeb campaigns"/>

                <p>4. Go to the <strong>Settings</strong> view.</p>
                <p>5. Go to the <strong>Postback Pixel</strong> tab.</p>
                <p>6. Click the <strong>Add New</strong> button.</p>
                <DocImage src="/images/docs/maxweb-int-6.jpg" alt="View MaxWeb campaigns"/>

                <p>
                    7. Paste the postback URL that you have copied from Skro in the <strong>URL</strong> text field.
                </p>
                <p>
                    8. Click the <strong>Save</strong> button.
                </p>
                <DocImage src="/images/docs/maxweb-int-8.jpg" alt="Add maxWeb postback pixel"/>

                <p>9. Go to the <strong>Dashboard</strong> view.</p>
                <p>10. Copy the offer URL to the clipboard.</p>
                <DocImage src="/images/docs/maxweb-int-7.jpg" alt="MaxWeb dashboard"/>


                <DocH2>Step 3. Add offer to Skro</DocH2>
                <DocDivider/>
                <p>
                    1. Go to <strong>Offers</strong> -> <strong>Create</strong>
                </p>
                <p>2. Choose your workspace.</p>
                <p>3. Add name.</p>
                <p>
                    4. Grab the offer link from MaxWeb and add it to offer URL field and
                    append <strong>{`subid2={clickId}`}</strong> to the end of your offer URL.
                </p>
                <p>5. Choose MaxWeb as Affiliate Network.</p>
                <DocImage src="/images/docs/maxweb-int-9.jpg" alt="Add MaxWeb offer"/>

                <DocH2>Step 4. Create campaign</DocH2>
                <DocDivider/>
                <p>1. Go to Campaigns -> Create.</p>
                <p>2. Fill all the fields where needed.</p>
                <p>3. On the final step you will get tracking link for your traffic source.</p>

            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default MaxWebIntegration;
