import React, {useState} from "react";
import {Alert, Tabs, Tab, Box, useTheme} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocDivider, DocH2, DocImage, DocRoot} from "../Docs";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";

function RetreaverIntegration() {

    const theme = useTheme();

    const [value, setValue] = useState("noRedirect");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const universalScript = `
        <script type="text/javascript">
            (function () {
                var scriptElement = document.createElement('script');
                scriptElement.type = 'text/javascript';
                scriptElement.async = true;
                scriptElement.defer = true;
                scriptElement.src = document.location.protocol + '//dist.routingapi.com/jsapi/v1/retreaver.min.js';
                scriptElement.onload = scriptElement.onreadystatechange = function () {
        
                    Retreaver.configure({
                        host: 'api.routingapi.com',
                        prefix: document.location.protocol === 'https:' ? 'https' : 'http'
                    });
        
                    var campaign = new Retreaver.Campaign({campaign_key: 'YOUR_CAMPAIGN_ID'});
        
                    function getSkroClickIdFromCookies() {
                        const skroClickId = document.cookie.split(";").map(x => x.trim()).filter(x => x.startsWith("skro-click-id"))[0];
                        return skroClickId ? skroClickId.split("=")[1] : "";
                    }
        
                    campaign.request_number(function (number) {
                        var link = '<a href="tel:' + number.get('number') + '">' + number.get('formatted_number') + '</a>';
                        document.getElementById('phone-number').innerHTML = link;
                        
                        number.add_tags({
                            skro_click_id: window.skclid !== undefined ? window.skclid : getSkroClickIdFromCookies()
                        });
                    });
                };
                (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(scriptElement);
            })();
        </script>
    `

    return (
        <DocRoot>
            <Box sx={{textAlign: "center"}}>
                <a href="https://retreaver.com/" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 150}} src="/images/integration/retreaver.svg" alt="Retreaver logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="Retreaver and Skro"/>
            <Box>
                <DocH2>Who we are</DocH2>
                <p>
                    <a href="https://retreaver.com" target="_blank" rel="noreferrer">
                        Retreaver
                    </a> helps marketers and agencies gather valuable data from every phone call. Their cloud based
                    software provides real-time, inbound call data by tagging, tracking and routing your callers to the
                    best person, department, or agent.
                </p>

                <DocH2>Step 1. Setup in Retreaver</DocH2>
                <DocDivider/>
                <p>1. Add the following essential elements to your Retreaver account:</p>
                <ul>
                    <li>
                        <a
                            href="https://help.retreaver.com/hc/en-us/articles/360001080226-How-to-Set-Up-Your-First-Call-Tracking-Campaign"
                            title="Step 1. Create a Retreaver Call Tracking Campaign."
                        >
                            Step 1. Create a Retreaver Call Tracking Campaign.
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://help.retreaver.com/hc/en-us/articles/360019902152-Real-time-Landing-Page-Tracking"
                            title="Step 2. Create A Number Pool For Your Campaign"
                        >
                            Step 2. Create A Number Pool For Your Campaign
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://help.retreaver.com/hc/en-us/articles/360019902152-Real-time-Landing-Page-Tracking"
                            title="Step 3. Configure Your Number Pool For Real-Time Tracking"
                        >
                            Step 3. Configure Your Number Pool For Real-Time Tracking
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://help.retreaver.com/hc/en-us/articles/360019902152-Real-time-Landing-Page-Tracking"
                            title="Step 4. Tell Retreaver Which Numbers To Replace On Your Landing Page"
                        >
                            Step 4. Tell Retreaver Which Numbers To Replace On Your Landing Page
                        </a>
                    </li>
                </ul>

                <DocH2>Step 2. Set Up Parameter Mapping for Skro Click ID</DocH2>
                <DocDivider/>

                <p>
                    1. In Retreaver account, go to <strong>Campaign settings</strong> -> <strong>Retreaver JS
                    Settings</strong> -> <strong>Parameter
                    Mappers</strong> and click on <strong>Edit</strong> button.
                </p>
                <DocImage src="/images/docs/retreaver/retreaver-int-3.png"
                          alt="Parametr mappers" sx={{pt: 2, pb: 2}}/>
                <p>
                    3. Add new URL parameter name mapping for Skro Click ID: <strong>skro_click_id</strong>. It is
                    needed to fire Skro postback URL with correct click id.
                </p>
                <DocImage src="/images/docs/retreaver/retreaver-int-2.png"
                          alt="Parametr mappers"/>

                <DocH2>Step 3. Install JS tracking scripts</DocH2>
                <DocDivider/>

                <Box sx={{pt: 2, pb: 2, fontWeight: 600, fontSize: 16}}>Select your tracking method:</Box>

                <Box sx={{pt: 2}}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        variant="scrollable"
                        scrollButtons="auto"
                        sx={{
                            "& .MuiTabs-indicator": {display: "none"}, // Remove bottom border indicator
                            mb: 6,
                            "& .MuiTabs-flexContainer": {
                                justifyContent: "left"
                            },
                            "&.Mui-selected": {
                                backgroundColor: "background.defaultBlue",
                                borderBottomWidth: "2px",
                                borderBottomStyle: "solid",
                                borderBottomColor: "red",
                                marginBottom: "-2px",
                                ":hover": {
                                    backgroundColor: "background.rowHover"
                                }
                            },
                            [theme.breakpoints.down(800)]: {
                                "& .MuiTabs-flexContainer": {
                                    justifyContent: "left"
                                }
                            }

                        }}
                    >
                        <Tab
                            label="Scripts for No-Redirect tracking" value="noRedirect"
                            sx={{
                                backgroundColor: theme.palette.common.darkBlue,
                                color: theme.palette.common.white,
                                borderRadius: "24px", // Rounded corners
                                mx: 1, // Margin between tabs
                                px: 2, // Padding inside tabs
                                "&.Mui-selected": {
                                    backgroundColor: theme.palette.common.darkBlue,
                                },
                            }}
                        />
                        <Tab
                            label="Script for Redirect tracking" value="redirect"
                            sx={{
                                backgroundColor: theme.palette.common.darkBlue,
                                color: theme.palette.common.white,
                                borderRadius: "24px", // Rounded corners
                                mx: 1, // Margin between tabs
                                px: 2, // Padding inside tabs
                                "&.Mui-selected": {
                                    backgroundColor: theme.palette.common.darkBlue,
                                },
                            }}
                        />
                    </Tabs>
                    <Box>
                        {value === "noRedirect" &&
                            <Box>
                                <p>1. Add your landing page as <strong>Offer</strong> in Skro.</p>
                                <DocImage src="/images/docs/retreaver/retreaver-int-8.png"
                                          alt="Add offer in Skro" sx={{pt: 2, pb: 2}}/>

                                <p>
                                    2. Select an Affiliate Network as <strong>Retreaver</strong>.
                                </p>
                                <DocImage src="/images/docs/retreaver/retreaver-int-7.png"
                                          alt="Add Retreaver as Affiliate Network in Skro" sx={{pt: 2, pb: 2}}/>

                                <p>
                                    3. Create a campaign in Skro.
                                </p>
                                <DocImage src="/images/docs/retreaver/retreaver-int-10.png"
                                          alt="Skro campaign"/>

                                <p>
                                    4. Copy Skro tracking pixel
                                    from <strong>Campaigns</strong> -> <strong>Links</strong> -> <strong>Direct</strong> and
                                    paste it to your landing page between <strong>HEAD</strong> tags.
                                </p>
                                <DocImage src="/images/docs/retreaver/retreaver-int-1.png"
                                          alt="retreaver integration step 1"/>
                                <p>
                                    5. Copy this <strong>Retreaver</strong> tracking pixel to landing your page after
                                    Skro tracking pixel code.
                                </p>
                                <Alert color="success" variant="filled" severity="success" sx={{mt: 2, mb: 2}}>
                                    Remember to replace the <strong>YOUR_CAMPAIGN_KEY</strong> element in the script
                                    with the actual <strong>Retreaver Campaign Key</strong>, which can be taken from
                                    your Campaign settings -> Retreaver JS Settings -> Code -> Advanced.
                                </Alert>
                                <Box>
                                    <SyntaxHighlighter language="javascript" customStyle={{fontSize: "12px"}}>
                                        {universalScript}
                                    </SyntaxHighlighter>
                                </Box>

                                <p>
                                    6. Copy Skro generated <strong>Offer URL</strong> with tracking params and submit
                                    to the traffic source for approval.
                                </p>
                                <DocImage src="/images/docs/retreaver/retreaver-int-11.png"
                                          alt="Skro tracking link"/>
                            </Box>
                        }
                        {value === "redirect" &&
                            <Box>
                                <p>1. Add your landing page as <strong>Offer</strong> in Skro.</p>
                                <DocImage src="/images/docs/retreaver/retreaver-int-9.png"
                                          alt="Add offer in Skro" sx={{pt: 2, pb: 2}}/>

                                <p>
                                    2. Select an Affiliate Network as <strong>Retreaver</strong>.
                                </p>
                                <DocImage src="/images/docs/retreaver/retreaver-int-7.png"
                                          alt="Add Retreaver as Affiliate Network in Skro" sx={{pt: 2, pb: 2}}/>

                                <Alert color="warning" variant="filled" severity="warning" sx={{mt: 2, mb: 2}}>
                                    {`It is very important to map Skro click id to your offer URL. You must use this parameter name and macro: &skro_click_id={clickId}`}
                                </Alert>
                                <p>3. Go back to Retreaver and navigate to <strong>Campaign
                                    settings</strong> -> <strong>Retreaver JS
                                    Settings</strong> -> <strong>Code</strong> and click
                                    on <strong>Copy</strong> button. Copy that code to your landing page between HEAD
                                    tags.
                                </p>
                                <DocImage src="/images/docs/retreaver/retreaver-int-6.png"
                                          alt="Retreaver tracking pixel"/>
                                <p>
                                    4. In Skro, go to <strong>Campaigns</strong> and click
                                    on <strong>Create</strong> button to add new campaign.
                                </p>
                                <DocImage src="/images/docs/retreaver/retreaver-int-10.png"
                                          alt="Skro campaign"/>

                                <p>
                                    5. When campaign is create, then copy the <strong>Campaign Tracking URL</strong> and
                                    submit to the traffic source for approval.
                                </p>
                                <DocImage src="/images/docs/retreaver/retreaver-int-12.png"
                                          alt="Skro tracking link"/>
                            </Box>
                        }
                    </Box>
                </Box>

                <DocH2>
                    Step 4. Set up Webhooks for Retreaver campaign
                </DocH2>
                <DocDivider/>
                <p>
                    In this example we are going to create two custom conversion
                    events, <strong>Phone Call</strong> and <strong>Purchase</strong>.
                </p>
                <p>
                    1. In Skro, go to <strong>Settings</strong> -> <strong>Custom
                    Conversions</strong> and click on <strong>Create</strong> button.</p>
                <p>
                    2. Fill all the fields and click on <strong>Save & Close</strong> button.
                </p>
                <DocImage src="/images/docs/retreaver/retreaver-int-5.png" alt="Event Purchase"/>
                <p>
                    If you are using custom tracking domain then replace skrotrack.com with your custom tracking domain
                    name.
                </p>
                <Alert color="success" variant="filled" severity="success" sx={{mt: 2, mb: 2}}>
                    {`Postback URL: https://skrotrack.com/postback?clickId=[skro_click_id]&payout=[revenue]&transactionId=[call_uuid]&p1=[caller_number]&p2=[caller_zip]&ce=Purchase`}
                </Alert>

                <p>
                    3. In Retreaver account, go to <strong>Campaign
                    settings</strong> -> <strong>Webhooks</strong> -> <strong>Add webhook</strong> -> choose the
                    condition <strong>If call converted</strong> -> select <strong>GET</strong> request -> paste the
                    Postback URL.
                </p>
                <DocImage src="/images/docs/retreaver/retreaver-int-14.png" alt="Retreaver first postback"/>

                <p>4. Create another custom conversion for <strong>Phone Call</strong> events.</p>
                <DocImage src="/images/docs/retreaver/retreaver-int-13.png" alt="Event Phoen Call"/>

                <Alert color="success" variant="filled" severity="success" sx={{mt: 2, mb: 2}}>
                    {`Postback URL: https://skrotrack.com/postback?clickId=[skro_click_id]&transactionId=[call_uuid]&p1=[caller_number]&p2=[caller_zip]&ce=PhoneCall`}
                </Alert>

                <p>
                    5. In Retreaver account, go to <strong>Campaign
                    settings</strong> -> <strong>Webhooks</strong> -> <strong>Add webhook</strong> -> choose the
                    condition <strong>When a call comes in</strong> -> select <strong>GET</strong> request -> paste the
                    Postback URL.
                </p>
                <DocImage src="/images/docs/retreaver/retreaver-int-4.png" alt="Retreaver second postback"/>

                <DocDivider/>
                <p>Integration set up is done.</p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default RetreaverIntegration;
