import React from "react";
import {Alert, Box} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocDivider, DocH2, DocImage, DocRoot} from "../Docs";

function RsocInuvoIntegration() {
    return (
        <DocRoot>
            <DocHeaderTitle title="RSOC Inuvo and Skro"/>
            <Box>
                <DocH2>Who we are</DocH2>
                <p>
                    <a href="https://inuvo.com" target="_blank" rel="noreferrer">
                        Inuvo
                    </a> is on a mission to revolutionize advertising by harnessing the power of AI to deliver
                    impactful brand messages that safeguard consumer privacy and uphold brand integrity.
                </p>
                <DocH2>About Inuvo</DocH2>
                <p>
                    Inuvo, Inc. (NYSE American: INUV) is an Artificial Intelligence based technology company that
                    designs, develops and patents proprietary advertising solutions that it sells through agencies,
                    directly to brands and integrated into advertising platforms.
                </p>
                <p>
                    The company’s core differentiators include its large language generative AI, designed to locate and
                    target online audiences without using consumer data, cookies or identifiers and its machine learning
                    predictive media mix AI, designed to empower CMO’s with the ability to confidently and statistically
                    optimize advertising spend across the omnichannel.
                </p>
                <h2>Inuvo RSOC Integration</h2>
                <p>
                    Skro integration with Inuvo gives you the ability to sync earnings data that has been
                    adjusted after different sources.
                </p>
                <ul>
                    <li>Revenue updates every hour.</li>
                    <li>Revenue updates for the last 3 days.</li>
                </ul>

                <DocH2>Step 1. Add integration</DocH2>
                <DocDivider/>
                <ul>
                    <li>Log in to your <strong>Skro</strong> account.</li>
                    <li>Navigate to <strong>Settings</strong> -> <strong>Integrations</strong>.</li>
                    <li>
                        Scroll down and click on the <strong>Add</strong> button in <strong>Revenue
                        Sync - RSOC</strong> section.
                    </li>
                </ul>
                <DocImage src="/images/docs/inuvo-int-1.png" alt="Inuvo RSOC integration step 1"
                />
                <ul>
                    <li>Click on the <strong>Inuvo</strong> logo.</li>
                </ul>
                <DocImage src="/images/docs/inuvo-int-2.png" alt="Inuvo RSOC integration step 2"
                />
                <ul>
                    <li><strong>Integration name</strong> Give a name to your integration.</li>
                    <li><strong>Inuvo domain name</strong> add your Inuvo offer domain name.</li>
                    <li>
                        <strong>S3 Bucket Name</strong> info you must ask from your Inuvo
                        account manager or write to Inuvo support.
                    </li>
                    <li>
                        <strong>S3 Bucket Region</strong> info you must ask from your Inuvo
                        account manager or write to Inuvo support.
                    </li>
                    <li>
                        <strong>AWS Access Key</strong> info you must ask from your Inuvo
                        account manager or write to Inuvo support.
                    </li>
                    <li>
                        <strong>AWS Access Secret</strong> info you must ask from your Inuvo
                        account manager or write to Inuvo support.
                    </li>
                    <li>
                        <strong>Net Revenue %</strong> It's a net revenue percentage. Example value: <strong>90</strong>
                    </li>
                    <li><strong>Inuvo Account Currency</strong> is the currency of the earnings.</li>
                    <li>
                        <strong>Custom Conversion Event</strong> is not mandatory field, but you can see a revenue and
                        conversions info in a different columns in your Skro reports.
                    </li>
                </ul>
                <DocImage src="/images/docs/inuvo-int-3.png" alt="Inuvo RSOC integration step 3"
                />
                <ul>
                    <li>Fill the form and click <strong>Save & Close</strong></li>
                </ul>

                <DocH2>Step 2. How to add Inuvo RSOC offer URL into Skro?</DocH2>
                <DocDivider/>

                <p>1. Go to <strong>Offers</strong> page.</p>
                <p>2. Click on <strong>Create</strong> button and add your offer URL and fill other fields.</p>
                <p>3. Map your <strong>RSOC Channel/Campaign ID</strong> on special field to track revenue correctly.</p>

                <DocImage src="/images/docs/inuvo-int-5.png" alt="Inuvo RSOC integration step 4" sx={{mb: 3}}/>

                <p>This is an example offer URL which goes to Skro:</p>
                <Alert severity="success" variant="filled" color="success">
                    {`https://example.com/?camp_id=345123&clickId={clickId}`}
                </Alert>

                <p>
                    Offer URL has 2 important parameters:
                </p>
                <p>
                    1. <strong>&camp_id=345123</strong> - is a Inuvo Campaign ID.
                </p>
                <p>
                    2. <strong>{`&clickId={clickId}`}</strong> - is a Skro Click ID to track conversions.
                </p>

                <Alert color="warning" variant="filled" severity="warning" sx={{mt: 2, mb: 2}}>
                    {`Note: If you are encoding Offer URL, do not encode Skro click id macro {clickId}`}
                </Alert>

                <DocH2>Step 3. Standard Postback URL for conversions</DocH2>
                <DocDivider/>
                <p>
                    1. Go to <strong>Affiliate Networks</strong> and click on <strong>Create</strong> button.
                </p>
                <p>
                    2. Search <strong>Inuvo</strong> and click on <strong>Inuvo logo</strong>.
                </p>
                <p>
                    3. Copy the postback URL.
                </p>
                <p>
                    4. Switch on <strong>Allow double conversions</strong>.
                </p>
                <p>5. If you are done then click on <strong>Save & Close</strong> button.</p>
                <DocImage src="/images/docs/inuvo-int-6.png" alt="Inuvo RSOC integration step 5"
                          sx={{mb: 1}}/>
                <p>
                    6. Give your Postback URL to your Inuvo account manager.
                </p>
                <Alert color="warning" variant="filled" severity="warning" sx={{mt: 2, mb: 2}}>
                    {`Postback URL: https://skrotrack.com/postback?clickId={clickId}`}
                </Alert>

                <DocH2>Step 4. Create a Custom Conversions for Inuvo events. (OPTIONAL)</DocH2>
                <DocDivider/>
                <p>
                    With custom conversion events feature it is possible to track revenue and clicks on a
                    different column. For Inuvo we are going to create event <strong>Purchase</strong>.
                </p>
                <DocImage src="/images/docs/system1-purchase.png" alt="System1 RSOC event purchase"/>

                <p>
                    Give your postback URL to your Inuvo account manager:
                </p>
                <Alert color="warning" variant="filled" sx={{mt: 2, mb: 4}}>
                    {`https://skrotrack.com/postback?clickId={clickId}&ce=Purchase`}
                </Alert>

                <DocDivider/>
                <p>Integration setup is done and your are ready to sync your earnings data.</p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default RsocInuvoIntegration;
