import React from "react";
import {Box} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocRoot} from "../Docs";

function AboutRsoc() {
    return (
        <DocRoot>
            <DocHeaderTitle title="About RSOC"/>
            <Box>
                <p>
                    Google's Related Search for Content (RSOC) is a Google-developed feature designed to enhance user
                    experience by facilitating a deeper dive into topics of interest. When a search is initiated on
                    Google, the search engine not only presents relevant results but also offers suggestions for
                    additional searches related to the initial query. These recommendations, known as RSOC, are
                    displayed either below the search results or next to them.
                </p>
                <p>
                    The purpose of RSOC is to help users find more comprehensive information or alternative perspectives
                    on their research topics. By providing related search suggestions, Google helps users find content
                    that might align more closely with their interests or offer a wider understanding of the subject.
                </p>
                <p>
                    For webmasters and content creators, RSOC offers a chance to draw more visitors to their websites.
                    Google’s related search suggestions, when aligned with a site’s content, can motivate users to
                    explore further. Therefore, aligning content with relevant search terms and topics can greatly
                    enhance site visibility and increase traffic from RSOC suggestions.
                </p>
                <p>
                    Overall, the RSOC feature is an invaluable tool for both internet users and content developers,
                    promoting effective information discovery and improving online content accessibility.
                </p>
                <h2>Understanding RSOC Arbitrage: How It Works?</h2>
                <p>
                    To begin with RSOC arbitrage, the first step is to set up a content website and publish several
                    articles to enhance your chances of approval by site monetizers.
                </p>
                <p>
                    Next, you'll need to apply for a RSOC search feed from providers like Google or other monetization
                    services.
                </p>
                <p>
                    Once your site is approved and you've integrated the RSOC feed onto your web pages, the next phase
                    involves driving traffic to your site. This can be achieved through various advertising channels
                    including Google Ads, Facebook/Meta Ads, TikTok Ads, and native advertising platforms like Taboola
                    and Outbrain.
                </p>
                <p><strong>Example of an RSOC Arbitrage Workflow</strong></p>
                <p>
                    Typically, RSOC arbitrage involves between one to three clicks, depending on the feed provider.
                    Here’s how it generally works: a user clicks on an ad from one of the advertising platforms, which
                    directs them to your content page. On this page, they find a block of keywords relevant to your
                    content. Clicking a keyword usually leads them to a search results or monetization page, generating
                    potential revenue.
                </p>
                <h2>RSOC Tracking</h2>
                <p>
                    Tracking RSOC is more challenging compared to AFD providers. Google only offers revenue data for
                    certain parameters such as geography, device, and channel, and does not allow for revenue breakdown
                    by click ID. This restriction makes it difficult to attribute revenue accurately on a per-click
                    basis.
                </p>
                <h2>RSOC Providers</h2>
                <p>
                    RSOC is available directly from Google (AdSense) or through third-party providers, each offering
                    similar functionality but with potential variations in reporting. Prominent third-party RSOC
                    providers include System1, ExplorAds, Airfind, Innuvo and others. Direct
                    collaboration with Google might provide a larger share of earnings, although it may come with
                    limited tracking capabilities. It's important to consider these factors when choosing a provider.
                </p>

            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default AboutRsoc;
