import React from "react";
import {Box, keyframes} from "@mui/material";

const scroll = keyframes`
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
`;

function PartnerLogos() {
    const logos = [
        {
            link: "https://hilltopads.com/",
            logo: "images/integration/hilltopads.svg",
            alt: "HilltopAds",
            logoSize: "120"
        },
        {link: "https://pushground.com/", logo: "images/partners/pushground.png", alt: "Pushground", logoSize: "120"},
        {link: "https://mylead.global/", logo: "images/partners/mylead.svg", alt: "MyLead", logoSize: "90"},
        {
            link: "https://clickdealer.com/",
            logo: "images/partners/clickdealer.svg",
            alt: "Clickdealer",
            logoSize: "110"
        },
        {link: "https://mondiad.com/", logo: "images/partners/mondiad.svg", alt: "Mondiad", logoSize: "90"},
        {
            link: "https://clickadilla.com/",
            logo: "images/partners/clickadilla.svg",
            alt: "ClickAdilla",
            logoSize: "130"
        },
        {link: "https://facebook.com", logo: "images/integration/meta.svg", alt: "Meta", logoSize: "100"},
        {link: "https://tiktok.com", logo: "images/integration/tiktok.svg", alt: "TikTok", logoSize: "100"},
        {link: "https://ads.google.com", logo: "images/integration/google_ads.svg", alt: "Google Ads", logoSize: "100"},
        {link: "https://taboola.com", logo: "images/integration/taboola.svg", alt: "Taboola", logoSize: "100"},
        {link: "https://outbrain.com", logo: "images/integration/outbrain.svg", alt: "Outbrain", logoSize: "100"},
        {link: "https://mgid.com", logo: "images/integration/mgid.svg", alt: "MGID", logoSize: "65"},
        {link: "https://revcontent.com", logo: "images/integration/revcontent.svg", alt: "Revcontent", logoSize: "120"},
        {link: "https://sedo.com", logo: "images/integration/sedo.svg", alt: "Sedo", logoSize: "100"},
        {link: "https://ads.com", logo: "images/integration/ads.svg", alt: "Ads.com", logoSize: "65"},
    ];

    return (
        <Box sx={{backgroundColor: "#fff"}} mt={14}>
            <Box
                sx={{
                    backgroundColor: "#f9f9f9",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    position: "relative",
                    width: "100%",
                    padding: "10px 0",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        gap: "40px",
                        animation: `${scroll} 50s linear infinite`,
                        width: "max-content",
                    }}
                >
                    {[...logos, ...logos].map((item, index) => (
                        <Box key={index} sx={{display: "flex", alignItems: "center"}}>
                            <a href={item.link} target="_blank" rel="noreferrer">
                                <img src={item.logo} alt={item.alt} width={item.logoSize}/>
                            </a>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}

export default PartnerLogos;
