import React from "react";
import {Alert, Box} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocDivider, DocH2, DocImage, DocRoot} from "../Docs";

function ClickBankIntegration() {
    return (
        <DocRoot>
            <Box sx={{textAlign: "center", pb: 2}}>
                <a href="https://clickbank.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 250}} src="/images/integration/clickbank.svg" alt="ClickBank logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="ClickBank and Skro"/>
            <div>
                <p>
                    <a href="https://clickbank.com" target="_blank" rel="noreferrer">ClickBank</a> is an online
                    marketplace and affiliate network that connects product creators (vendors)
                    with affiliate marketers. It primarily focuses on digital products such as e-books, courses,
                    software, and membership sites but also includes some physical products.
                </p>

                <p>Key Features of ClickBank:</p>
                <ul>
                    <li>Affiliate Marketplace: ClickBank has a vast marketplace where affiliates can browse and promote
                        products from various niches, earning commissions on successful sales.
                    </li>
                    <li>High Commission Rates: Many products offer commissions of 50% or higher, making it attractive
                        for affiliates.
                    </li>
                    <li>Vendor Platform: Product creators can sell their digital or physical products, set commission
                        rates, and leverage ClickBank’s payment processing and affiliate management.
                    </li>
                    <li>Global Reach: ClickBank supports vendors and affiliates worldwide, with payments available in
                        multiple currencies.
                    </li>
                    <li>Integrated Payment Processing: ClickBank handles transactions, refunds, and chargebacks,
                        simplifying sales for vendors.
                    </li>
                </ul>

                <Alert severity="warning" variant="filled">
                    There are two types of integrations. We suggest you to use their newest method which is global S2S
                    postback option.
                </Alert>

                <DocH2>Step 1. ClickBank (S2S) global postback - NEW method</DocH2>
                <DocDivider/>

                <p>
                    1. Log in to your Skro account and go to <strong>Custom Conversions</strong> and add 3 different
                    events there.
                </p>
                <p>
                    2. First event name is <strong>Upsell</strong>.
                </p>
                <DocImage src="/images/docs/clickbank/clickbank-s2s-4.png" alt="Upsell"/>
                <p>
                    3. Second event name is <strong>InitiateCheckout</strong>.
                </p>
                <DocImage src="/images/docs/clickbank/clickbank-s2s-3.png" alt="InitiateCheckout"/>
                <p>
                    4. Third event name is <strong>Purchase</strong>.
                </p>
                <DocImage src="/images/docs/clickbank/clickbank-s2s-5.png" alt="Purchase"/>

                <p>
                    5. Now, go back to Clickbank and set up Postback URL and customize it as per the target event types.
                </p>
                <p>
                    6. In your ClickBank account go to <strong>Integrations
                </strong> -> <strong>Postback/Pixels</strong> -> <strong>Add Integrations</strong> -> <strong>Custom
                    Postback/Pixel</strong>
                </p>
                <p>
                    7. In the <strong>Event Types</strong> drop-down select <strong>Upsell Purchase</strong>, <strong>Initial
                    Purchase</strong>, <strong>Initial Order Form Impression</strong> and enable the <strong>Customize
                    URL per event type</strong> toggle -> add Skro Postback URL's and add &ce=eventname to each Postback
                    URL with the event name you want to track and then click on <strong>Save</strong> button.
                </p>
                <p>
                    Upsell Purchase Postback URL:
                </p>
                <Alert severity="info" variant="filled">
                    <strong>{`https://skrotrack.com/postback?clickId={tid}&payout={affiliate_earnings}&transactionId={order_id}&ce=Upsell`}</strong>
                </Alert>
                <p>
                    Initial Order Form Impression Postback URL:
                </p>
                <Alert severity="info" variant="filled">
                    <strong>{`https://skrotrack.com/postback?clickId={tid}&ce=InitiateCheckout`}</strong>
                </Alert>
                <p>
                    Initial Purchase Postback URL:
                </p>
                <Alert severity="info" variant="filled">
                    <strong>{`https://skrotrack.com/postback?clickId={tid}&payout={affiliate_earnings}&transactionId={order_id}&ce=Purchase`}</strong>
                </Alert>

                <DocImage sx={{pt: 2}} src="/images/docs/clickbank/clickbank-s2s-1.png" alt="Add ClickBank S2S integration"/>

                <h2>Step 2. Add ClickBank offer to Skro</h2>
                <DocDivider/>

                <p>1. Navigate to <strong>Offers</strong> and press <strong>Create</strong>.</p>
                <p>2. Enter your offer name.</p>
                <p>
                    3. Copy the offer link from ClickBank to the <strong>Offer URL</strong> field and
                    append <strong>&tid={`clickId`}</strong> parameter to the end of the offer URL.
                </p>
                <DocImage src="/images/docs/clickbank-offer-form.jpg" alt="ClickBank as offer form"/>
                <p>
                    4. Choose <strong>ClickBank</strong> from the Affiliate Network dropdown menu or create new.
                </p>
                <p>
                    5. Clickbank setup as <strong>Affiliate Network</strong> on Skro.
                </p>
                <DocImage src="/images/docs/clickbank/clickbank-s2s-2.png"
                          alt="Add ClickBank as affiliate network into Skro"/>
                <p>
                    Integration setup is done and your are ready to create a campaign.
                </p>

                <DocH2>Step 1. ClickBank (INS) integration - OLD method</DocH2>
                <DocDivider/>
                <Alert severity="warning" variant="filled">
                    This is old method and will be deprecated soon.
                </Alert>
                <ul>
                    <li>Log in to your ClickBank account.</li>
                    <li>Navigate to <strong>Accounts</strong> and click on your account.</li>
                    <li>Press <strong>Vendor Settings</strong> and select <strong>My site</strong> on ClickBank panel.
                    </li>
                </ul>
                <DocImage src="/images/docs/clickbank-1.jpg" alt="Add ClickBank integration"
                />
                <ul>
                    <li>Scroll down to <strong>Advanced Tools</strong> section.</li>
                    <li>Press <strong>Edit</strong>.</li>
                </ul>
                <DocImage src="/images/docs/clickbank-2.jpg" alt="Add ClickBank integration"
                />
                <ul>
                    <li>Click on <strong>Request access</strong>.</li>
                </ul>
                <DocImage src="/images/docs/clickbank-3.jpg" alt="Add ClickBank integration"
                />
                <ul>
                    <li>Mark all values <strong>Yes</strong>.</li>
                    <li>Read and confirm the "Terms & Conditions" and press <strong>Save changes</strong>.</li>
                </ul>
                <DocImage src="/images/docs/clickbank-4.jpg" alt="Add ClickBank integration"
                />
                <ul>
                    <li>
                        If you don't have a <strong>Secret Key</strong> then then enter your secret key in the
                        Secret Key field and press <strong>Save Changes</strong>. Your secret key is a string of up
                        to 16 characters, including numbers and capital letters. Your instant notifications will be
                        encrypted using that secret key.
                    </li>
                    <li>
                        If you already have a <strong>Secret Key</strong> then copy to the clipboard.
                    </li>
                </ul>
                <DocImage src="/images/docs/clickbank-5.jpg" alt="Add ClickBank integration"
                />
                <ul>
                    <li>Log in to your Skro account.</li>
                    <li>Navigate to <strong>Settings</strong> -> <strong>Integrations</strong>.</li>
                    <li>Press <strong>Add</strong> button on the Affiliate Networks section.</li>
                </ul>
                <DocImage src="/images/docs/affiliate-network-integration.jpg" alt="Integrations view"
                />
                <ul>
                    <li>Choose <strong>ClickBank</strong>.</li>
                </ul>
                <DocImage src="/images/docs/choose-clickbank-integration.jpg" alt="Choose ClickBank integration"
                />
                <ul>
                    <li>Enter your integration name to the <strong>Integration Name</strong> field.</li>
                    <li>Paste your secret key to the <strong>ClickBank Secret Key</strong> field.</li>
                    <li>Press <strong>Generate Instant Notification URL</strong>.</li>
                </ul>
                <DocImage src="/images/docs/clickbank-add-integration-1.jpg" alt="Add ClickBank integration"
                />
                <ul>
                    <li>
                        Copy the <strong>Instant Notification URL</strong> to the clipboard
                        and press <strong>Save & Close</strong>.
                    </li>
                    <li>Go back to ClickBank.</li>
                </ul>
                <DocImage src="/images/docs/clickbank-add-integration-2.jpg" alt="Add ClickBank integration"
                />
                <ul>
                    <li>
                        Paste your <strong>Instant Notification URL</strong> copied from Skro.
                    </li>
                    <li>Choose version <strong>7.0</strong> and press <strong>TEST IPN</strong> button.</li>
                    <li>
                        If the <strong>INS URL</strong> is introduced correctly, the <strong>Verified</strong> sign
                        will appear.
                    </li>
                </ul>
                <DocImage src="/images/docs/clickbank-5.jpg" alt="Add ClickBank integration"
                />
                <p>
                    Press <strong>Save Changes</strong> to finish the setup process. Instant Notification URL with
                    verified sign will be available on the Advanced Tools section.
                </p>
                <DocImage src="/images/docs/clickbank-7.jpg" alt="Add ClickBank integration"
                />
                <p>
                    Instant Notification Access settings are finished and you are now successfully integrated.
                </p>

                <DocH2>Step 2. How to set up tracking for Initiate Checkout?</DocH2>
                <DocDivider/>
                <ul>
                    <li>
                        Go back to Skro and navigate
                        to <strong>Settings</strong> -> <strong>Custom Conversions</strong>.
                    </li>
                    <li>Enter name <strong>Initiate Checkout</strong>.</li>
                    <li>Enter <strong>InitiateCheckout</strong> to <strong>Values Of The ce Parameter</strong> and press
                        enter.
                    </li>
                    <li>
                        Mark the <strong>Send postback to Traffic Source</strong>, if you want to track given
                        event in your traffic source. Actual event value what is placed in "Values Of The ce Parameter"
                        field, will be postback to your traffic source as an event name. Given example is suitable
                        for Facebook InitiateCheckout event tracking.
                    </li>
                </ul>
                <DocImage src="/images/docs/clickbank-custom-conversion.jpg" alt="Add custom conversion"/>
                <ul>
                    <li>
                        Go back to ClickBank and navigate to <strong>Clickbank account</strong> -> <strong>Vendor
                        settings</strong> -> <strong>My site</strong> -> scroll down to the
                        Integrated sales reporting section and press <strong>Add tracking code</strong>.
                    </li>
                </ul>
                <DocImage src="/images/docs/clickbank-7.jpg" alt="Add ClickBank integration"/>
                <ul>
                    <li>
                        Choose the type <strong>Tracking pixel</strong> and press <strong>Add</strong> button.
                    </li>
                    <li>
                        Enter your <strong>Tracking pixel URL</strong> from Skro <strong>Custom
                        Conversions</strong> page.
                        In our example the value is: <strong>https://skrotrack.com/postback?ce=InitiateCheckout</strong>
                    </li>

                </ul>

                <Alert severity="warning" variant="filled" sx={{mb: 3}}>
                    The pasted URL SHOULD NOT BE precisely copied from the affiliate network Postback URL, it SHOULD BE
                    of the following format: https://skrotrack.com/postback?ce=InitiateCheckout, where you
                    should replace the “skrotrack.com” part with the tracking domain you use for this particular
                    campaign.
                </Alert>

                <ul>
                    <li>
                        Select <strong>Affiliate</strong> if you are promoting ClickBank products from the specific
                        sales account you are working in. If you select Affiliate, you will receive information relevant
                        to the affiliate process.
                    </li>
                    <li>
                        Select <strong>Seller</strong> if you are creating products for promotion in the account. If you
                        select Seller, you will receive information relevant to the vendor process.
                    </li>
                    <li>
                        Check the <strong>Order Form</strong> box to indicate that the pixel will fire on the order
                        form.
                    </li>
                    <li>
                        Leave all fields under the <strong>Order Form Event</strong> header the same except
                        the <strong>AFFILIATE_TRACKING_CODE</strong> field. Change that
                        from <strong>trackingCodes</strong> to <strong>clickId</strong> and
                        press <strong>Save</strong> to apply the changes.
                    </li>
                </ul>

                <DocImage src="/images/docs/clickbank-8.jpg" alt="Add ClickBank integration"/>

                <DocH2>Step 3. Add ClickBank as an Affiliate Network to Skro</DocH2>
                <DocDivider/>
                <ul>
                    <li>Navigate to <strong>Affiliate Networks</strong> and press <strong>Create</strong> button.</li>
                    <li>Choose <strong>ClickBank</strong> integration.</li>
                </ul>
                <DocImage src="/images/docs/affiliate-network-choose.jpg" alt="Choose ClickBank"/>

                <ul>
                    <li>Press <strong>Save & Close</strong></li>
                </ul>
                <DocImage src="/images/docs/clickbank-affiliate-network.jpg" alt="ClickBank as affiliate network"/>


                <DocH2>Step 4. Add ClickBank offer to Skro</DocH2>
                <DocDivider/>
                <ul>
                    <li>Navigate to <strong>Offers</strong> and press <strong>Create</strong>.</li>
                    <li>Enter your offer name.</li>
                    <li>
                        Copy the offer link from ClickBank to the <strong>Offer URL</strong> field and
                        append <strong>tid={`clickId`}</strong> parameter to the end of the offer URL.
                    </li>
                    <li>
                        Choose <strong>ClickBank</strong> from the Affiliate Network dropdown menu.
                    </li>
                </ul>
                <DocImage src="/images/docs/clickbank-offer-form.jpg" alt="ClickBank as offer form"/>

                <p>Integration setup is done and your are ready to create a campaign.</p>
            </div>
            <MoreQuestionsFooterText/>
        </DocRoot>
    )
        ;
}

export default ClickBankIntegration;
