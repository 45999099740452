import React from "react";
import {Grid, Typography} from "@mui/material";
import {
    Security,
    LocalMall,
    EmojiEvents,
    Group,
    Assessment,
    Directions,
    Dns,
    AttachMoney,
    CallSplit,
    Visibility,
    VisibilityOff,
    BugReport,
    ListAlt,
    Money,
    PostAdd,
    SettingsSuggest
} from '@mui/icons-material';
import FeatureCard from "./FeatureCard";
import {ContainerFluid, ContainerFluidLgPaddingTop, LgPaddingTop} from "./Home";

const iconSize = 36;
const primary = "rgba(68, 199, 103, 1)";

const features = [
    {
        color: primary,
        headline: "Optimization",
        text: "Monitor your campaigns performance in real-time with a clear and intuitive dashboard. Manage your ads within Skro’s ads manager — toggling them on or off, adjust bids and budgets.",
        icon: <SettingsSuggest sx={{fontSize: iconSize, primary}}/>,
        mdDelay: "0",
        smDelay: "0"
    },
    {
        color: primary,
        headline: "Anti-Fraud Protection",
        text: "Monetizing bot traffic is not feasible and bots can significantly harm your ad performance. Protect your ad campaigns by blocking bots to ensure that only high-quality human traffic is driving your results.",
        icon: <BugReport sx={{fontSize: iconSize}}/>,
        mdDelay: "0",
        smDelay: "0"
    },
    {
        color: primary,
        headline: "Cookieless Tracking",
        text: "Use the power of direct tracking with tracking pixel to obtain precise traffic data for Meta Ads, Google Ads, and Microsoft Ads.",
        icon: <VisibilityOff sx={{fontSize: iconSize}}/>,
        mdDelay: "0",
        smDelay: "0"
    },
    {
        color: primary,
        headline: "Smartlinks",
        text: "Supercharge your campaign funnel by extending it and creating a complex rules to your visitors.",
        icon: <CallSplit sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Workspaces & Multi-User Access",
        text: "Efficiently coordinate tasks within your team by assigning distinct roles to each member. Establish separate workspaces for various campaigns and elements, empowering team members with delegated management responsibilities.",
        icon: <Group sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Templates",
        text: "Access a comprehensive collection of 250+ pre-filled templates designed for affiliate networks, along with an additional 100+ templates for various traffic sources.",
        icon: <PostAdd sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Custom Domains With SSL",
        text: "Each of your links or domains are provided with free SSL to prevent your campaigns from being considered suspicious by Google, Safari or other browsers.",
        icon: <Dns sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Multi-Currency",
        text: "Explore your aggregated reports with flexibility by accessing them in 169 diverse currencies. Customize your account's main currency to either EUR or USD.",
        icon: <Money sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Multi-Dimensional Reporting",
        text: "Unlock the power of effective optimization by easily accessing the information you need. Leverage user-based columns to gain insightful perspectives into your ad campaigns. Enhance your analysis with 34 diverse data metrics and organize your data into three levels of grouping.",
        icon: <ListAlt sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Landing Page Protection",
        text: "Safeguard your landing pages against direct visits by implementing protection measures that analyze the time lapse between redirecting a user to the landing page and their actual visit to it.",
        icon: <Security sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Multi-Cost and Payout Tracking",
        text: "Skro lets you choose your cost model (Auto, CPA, CPC, CPM, or RevShare) and payout type (auto or manual), providing detailed profit metrics to help you make data-driven performance decisions.",
        icon: <Assessment sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Redirect Methods",
        text: "Easily direct your visitors to your offers with methods like HTTP 302, meta refresh, or double meta refresh. Using a cloaking domain to hide your traffic source and pre-lander from your affiliate network.",
        icon: <Directions sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Logs",
        text: "Dive deep into the analytics of your clicks, conversions and postbacks with Skro. Gain comprehensive insights into every single interaction and effortlessly identify any data mismatches between your affiliate network and our platform.",
        icon: <Visibility sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Refer & Earn",
        text: "Unlock your earnings with the Skro affiliate program! Join for free and set up with ease – all you need to do is share your referral link. Connect your friends to a trusted tool and enjoy a 10% lifetime commission on every purchase they make. It's the simplest way to earn money. Join now and start turning your connections into cash!",
        icon: <AttachMoney sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Custom Conversion Events",
        text: "Empower your tracking capabilities with custom conversion events on Skro! Monitor a diverse range of up to 20 events, providing you with a comprehensive overview. Whether it's deposits, app installs, purchases, instant checkouts, or any other event you wish to track, Skro ensures flexibility and precision in capturing the data that matters most to you.",
        icon: <EmojiEvents sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "ClickBank Integration",
        text: "Integrate ClickBank with Skro by transmitting conversion data through the ClickBank instant notification URL or with just a regular global postback URL. Track different events like initiate checkout, upsell, chargeback and sales.",
        icon: <span style={{fontSize: 28, letterSpacing: 2, fontWeight: 600, marginBottom: -4}}>CB</span>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Ecom Integration",
        text: "Say goodbye to hours of manual effort in connecting advertising costs across various channels to Shopify sales and revenue. With our consolidated dashboard, you can effortlessly make campaign and budget decisions. Track your Shopify sales and optimize your ads all in one place.",
        icon: <LocalMall sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
    {
        color: primary,
        headline: "Deploy Websites on AWS",
        text: "Our platform takes the complexity out of managing domains, SSL certificates, and website deployments. From automatic SSL generation and S3 bucket setup to one-click deployments, we provide everything you need to get your site live and secure.",
        icon: <LocalMall sx={{fontSize: iconSize}}/>,
        mdDelay: "100",
        smDelay: "100"
    },
];

function FeatureSection() {
    return (
        <LgPaddingTop sx={{backgroundColor: "common.white"}}>
            <ContainerFluidLgPaddingTop>
                <Typography variant="h3" align="center" sx={{paddingBottom: 3}}>
                    Features
                </Typography>
                <Typography align="center" sx={{pt: 3, pb: 8, color: "#555", fontSize: 24}}>
                    Our features will change the way you do <span style={{fontWeight: 600, color: primary}}>affiliate marketing</span>
                </Typography>
                <ContainerFluid>
                    <Grid container spacing={6}>
                        {features.map(element => (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={4}
                                xl={4}
                                data-aos="zoom-in-up"
                                data-aos-delay={element.mdDelay}
                                key={element.headline}
                                sx={{pt: 4}}
                            >
                                <FeatureCard
                                    Icon={element.icon}
                                    color={element.color}
                                    headline={element.headline}
                                    text={element.text}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </ContainerFluid>
            </ContainerFluidLgPaddingTop>
        </LgPaddingTop>
    );
}

export default FeatureSection;
