import React from "react";
import {Alert, Box} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocDivider, DocH2, DocImage, DocRoot} from "../Docs";

function RsocPredictoIntegration() {
    return (
        <DocRoot>
            <Box sx={{textAlign: "center"}}>
                <a href="https://predicto.ai" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 150}} src="/images/integration/predicto.png" alt="Predicto.ai logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="RSOC Predicto.ai and Skro"/>
            <Box>
                <DocH2>Who we are</DocH2>
                <p>
                    <a href="https://predicto.ai" target="_blank" rel="noreferrer">
                        Predicto
                    </a> is a data-driven engagement platform. Their leading technology creates a disruption in the
                    content ecosystem by maximizing it's potential while reaching the right audience at any given time.
                </p>
                <h2>Integration benefits</h2>
                <p>
                    Integration enables seamless revenue data synchronization across channels, ensuring accurate
                    tracking even after adjustments from various traffic sources. It also consolidates your ads,
                    conversions, and revenue, giving you a clear and comprehensive view of your ad performance.
                </p>
                <ul>
                    <li>Revenue updates every hour.</li>
                    <li>Revenue updates for the last 3 days.</li>
                </ul>

                <DocH2>Step 1. Add integration</DocH2>
                <DocDivider/>
                <p>1. Log in to your <strong>Skro</strong> account.</p>
                <p>2. Navigate to <strong>Settings</strong> -> <strong>Integrations</strong>.</p>
                <p>
                    3. Scroll down and click on the <strong>Add</strong> button in <strong>Revenue
                    Sync - RSOC</strong> section.
                </p>
                <DocImage src="/images/docs/rsocpredicto/rsoc-predicto-1.png" alt="Predicto.ai RSOC integration step 1"
                />
                <p>4. Click on the <strong>Predicto</strong> logo.</p>
                <DocImage src="/images/docs/rsocpredicto/rsoc-predicto-2.png" alt="Predicto.ai RSOC integration step 2"
                />
                <ul>
                    <li><strong>Integration Name</strong> name of your integration.</li>
                    <li>
                        <strong>API Key</strong> is your Predicto API key.
                    </li>
                    <li><strong>Account Currency</strong> is the currency of the earnings.</li>
                    <li>
                        <strong>Custom Conversion Event</strong> is not required field. If you are using Custom
                        Conversion on your postback URL, then you will need to select your event here, otherwise your
                        revenue isn't synced correctly.
                    </li>
                </ul>
                <DocImage src="/images/docs/rsocpredicto/rsoc-predicto-3.png" alt="Predicto.ai RSOC integration step 3"
                />

                <p>5. Fill the form and click <strong>Save & Close</strong></p>

                <DocH2>Step 2. Add Predicto RSOC offer in Skro</DocH2>
                <DocDivider/>

                <p>1. Go to <strong>Offers</strong> page.</p>
                <p>2. Click on <strong>Create</strong> button and fill fields where needed.</p>
                <p>
                    3. Map your <strong>RSOC Channel/Campaign ID</strong> on special field to track revenue
                    correctly. You should map <strong>ONLY</strong> Channel ID. In this example it is <strong>ch123</strong>
                </p>

                <DocImage src="/images/docs/rsocpredicto/rsoc-predicto-4.png" alt="Predicto.ai RSOC integration step 4"
                          sx={{mb: 3}}/>

                <p>This is an example offer URL which goes to Skro:</p>
                <Alert severity="success" variant="filled" color="success">
                    {`https://example.com/?utm_term={clickId}&cid=ch38485+ch123`}
                </Alert>

                <p>
                    Offer URL has 2 important parameters:
                </p>
                <p>
                    1. <strong>&cid=ch38485+ch123</strong> - You should pass your company channel and campaign channel
                    in this param.
                </p>
                <p>
                    2. <strong>{`&utm_term={clickId}`}</strong> - is a Skro Click ID to track conversions with postback
                    URL.
                </p>

                <Alert color="warning" variant="filled" severity="warning" sx={{mt: 2, mb: 2}}>
                    {`Note: If you are encoding Offer URL, do not encode Skro click id macro {clickId}`}
                </Alert>

                <DocH2>Step 3. Standard Postback URL for conversions</DocH2>
                <DocDivider/>
                <p>
                    1. Go to <strong>Affiliate Networks</strong> and click on <strong>Create</strong> button.
                </p>
                <p>
                    2. Search <strong>Predicto</strong> and click on <strong>Predicto logo</strong>.
                </p>
                <p>
                    3. Copy the postback URL.
                </p>
                <p>
                    4. Switch on <strong>Allow double conversions</strong>.
                </p>
                <p>5. If you are done then click on <strong>Save & Close</strong> button.</p>
                <DocImage src="/images/docs/rsocpredicto/rsoc-predicto-6.png" alt="Predicto.ai RSOC integration step 5"
                          sx={{mb: 1}}/>

                <p>
                    6. Give your Postback URL to your account manager and tell them
                    which <strong>UTM param</strong> you are using for Skro Click ID tracking.
                </p>
                <Alert color="warning" variant="filled" severity="warning" sx={{mt: 2, mb: 2}}>
                    {`Postback URL: https://skrotrack.com/postback?clickId=REPLACE&p1=KW_REPLACE`}
                </Alert>

                <DocH2>Step 4. Use Custom Conversions (OPTIONAL)</DocH2>
                <DocDivider/>
                <p>
                    With custom conversion events feature it is possible to track different events, revenue and clicks
                    on a different columns. For Predicto we are going to create event <strong>Purchase</strong>.
                </p>
                <p>1. Go back to Skro and navigate to the <strong>Settings</strong> -> <strong>Custom
                    Conversions</strong> and click on <strong>Create</strong> button.</p>
                <p>
                    2. Fill all the fields and click on <strong>Save & Close</strong> button.
                </p>
                <DocImage src="/images/docs/rsocadscom/rsoc-ads-com-7.png" alt="Ads.com RSOC event purchase"/>

                <p>
                    3. Give your Postback URL to your account manager and tell them
                    which <strong>UTM param</strong> you are using for Skro Click ID tracking.
                </p>
                <Alert color="warning" variant="filled" severity="warning" sx={{mt: 2, mb: 2}}>
                    {`Postback URL: https://skrotrack.com/postback?ce=Purchase&clickId=REPLACE&p1=KW_REPLACE`}
                </Alert>

                <DocDivider/>
                <p>Integration setup is done and your are ready to sync your earnings data.</p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default RsocPredictoIntegration;
