import React, {Fragment, useEffect} from "react";
import PropTypes from "prop-types";
import TreeViewNav from "./TreeViewNav";
import {Box, Divider, Link} from "@mui/material";
import {styled} from "@mui/system";
import {Link as RouterLink} from "react-router-dom";

export const DocRoot = styled(Box)(({theme}) => ({
    margin: "0 auto",
    width: '100%',
    maxWidth: 1280,
    "& ul": {
        paddingTop: 16,
        paddingBottom: 8
    }
}));

export const DocImage = styled("img")(({theme}) => ({
    width: "100%",
    maxWidth: 700,
    height: "auto"
}));

export const DocDivider = styled(Divider)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    borderRadius: "5px",
    opacity: 1,
    height: "3px",
    marginBottom: "24px"
}));

export const DocH2 = styled("h2")(({theme}) => ({
    paddingTop: "24px",
    marginBottom: "16px"
}));

export const DocLinkBox = ({to = "/", title = ""}) => {
    const scrollToTop = () => {
        const element = document.getElementById("docContent");
        element.scrollTo({top: 0, behavior: "smooth"});
    };
    return (
        <Box sx={{
            backgroundColor: "#f9f9f9",
            borderRadius: 5,
            padding: "24px 16px",
            marginTop: 5,
            marginBottom: 5
        }}>
            <Link
                component={RouterLink}
                to={to}
                onClick={scrollToTop}
            >
                {title}
            </Link>
        </Box>
    );
}

function Docs(props) {

    const {selectDocs} = props;

    useEffect(() => {
        selectDocs();
    }, [selectDocs]);

    return (
        <Fragment>
            <Box sx={{width: "100%", maxWidth: "100%", margin: "0 auto"}}>
                <TreeViewNav {...props}/>
            </Box>
        </Fragment>
    );
}

Docs.propTypes = {
    selectDocs: PropTypes.func.isRequired
};

export default Docs;
