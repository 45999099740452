import React from "react";
import {Alert, Box} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocDivider, DocH2, DocImage, DocRoot} from "../Docs";

function RsocSedoIntegration() {
    return (
        <DocRoot>
            <Box sx={{textAlign: "center"}}>
                <a href="https://sedo.com" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 150}} src="/images/integration/sedo.svg" alt="Sedo logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="RSOC Sedo and Skro"/>
            <Box>
                <DocH2>Who we are</DocH2>
                <p>
                    <a href="https://sedo.com" target="_blank" rel="noreferrer">
                        Sedo
                    </a> is the world's largest domain marketplace. With over 19 million listed domains and 2
                    million customers. Sedo is the world's largest platform for the professional trading of web
                    addresses.
                </p>
                <p>
                    Sedo stands for "Search Engine for Domain Offers" and their online platform currently provides not
                    only a convenient searchable domain marketplace, but also extensive services for domain buyers and
                    sellers: from domain parking to value appraisals all the way up to the brokerage and marketing of
                    domains by our professional brokerage team.
                </p>
                <h2>Integration benefits</h2>
                <p>
                    Integration enables seamless revenue data synchronization across channels, ensuring accurate
                    tracking even after adjustments from various traffic sources. It also consolidates your ads,
                    conversions, and revenue, giving you a clear and comprehensive view of your ad performance.
                </p>
                <ul>
                    <li>Revenue updates every hour.</li>
                    <li>Revenue updates for the last 3 days.</li>
                </ul>

                <DocH2>Step 1. Add integration</DocH2>
                <DocDivider/>
                <p>1. Log in to your <strong>Skro</strong> account.</p>
                <p>2. Navigate to <strong>Settings</strong> -> <strong>Integrations</strong>.</p>
                <p>
                    3. Scroll down and click on the <strong>Add</strong> button in <strong>Revenue
                    Sync - RSOC</strong> section.
                </p>
                <DocImage src="/images/docs/rsocsedo/rsoc-sedo-1.png" alt="Sedo RSOC integration step 1"
                />
                <p>4. Click on the <strong>Sedo</strong> logo.</p>
                <DocImage src="/images/docs/rsocsedo/rsoc-sedo-2.png" alt="Sedo RSOC integration step 2"
                />
                <ul>
                    <li><strong>Integration Name</strong> name of your integration.</li>
                    <li>
                        <strong>RSOC Username</strong> is your Sedo RSOC login.
                    </li>
                    <li>
                        <strong>RSOC Password</strong> is your Sedo RSOC login password.
                    </li>
                    <li><strong>Account Currency</strong> is the currency of the earnings.</li>
                    <li>
                        <strong>Custom Conversion Event</strong> is not required field. If you are using Custom
                        Conversion on your postback URL, then you will need to select your event here, otherwise your
                        revenue isn't synced correctly.
                    </li>
                </ul>
                <DocImage src="/images/docs/rsocsedo/rsoc-sedo-3.png" alt="Sedo RSOC integration step 3"
                />

                <p>5. Fill the form and click <strong>Save & Close</strong></p>

                <DocH2>Step 2. Add Sedo RSOC offer in Skro</DocH2>
                <DocDivider/>

                <p>1. Go to <strong>Offers</strong> page.</p>
                <p>2. Click on <strong>Create</strong> button and fill all the fields where needed.</p>
                <p>
                    3. Map your <strong>RSOC Channel/Campaign ID</strong> on special field to track revenue
                    correctly. In this example it is <strong>1234567</strong>
                </p>

                <DocImage src="/images/docs/rsocsedo/rsoc-sedo-5.png" alt="Sedo RSOC integration step 4"
                          sx={{mb: 3}}/>

                <p>This is an example offer URL which goes to Skro:</p>
                <Alert severity="success" variant="filled" color="success">
                    {`https://mydomain.info/all-you-need-to-know-about-non-invasive-fat-addition/?click_id={clickId}&campaign=1234567&adtitle=belly%20fat%20removal&terms=term1%2Cterm2%2Cterm3&count=3&style=blue`}
                </Alert>

                <p>
                    Offer URL has 2 important parameters:
                </p>
                <p>
                    1. <strong>&campaign=1234567</strong> - You should pass your Campaign ID in this param.
                </p>
                <p>
                    2. <strong>{`&click_id={clickId}`}</strong> - You should pass Skro Click ID to track conversions with
                    postback.
                </p>

                <Alert color="warning" variant="filled" severity="warning" sx={{mt: 2, mb: 2}}>
                    {`Note: If you are encoding Offer URL, do not encode Skro click id macro {clickId}`}
                </Alert>

                <DocH2>Step 3. Standard Postback URL for conversions</DocH2>
                <DocDivider/>
                <p>
                    1. Go to <strong>Affiliate Networks</strong> and click on <strong>Create</strong> button.
                </p>
                <p>
                    2. Search <strong>Sedo</strong> and click on <strong>Sedo logo</strong>.
                </p>
                <p>
                    3. Copy the postback URL.
                </p>
                <p>
                    4. Switch on <strong>Allow double conversions</strong>.
                </p>
                <p>5. If you are done then click on <strong>Save & Close</strong> button.</p>
                <DocImage src="/images/docs/rsocsedo/rsoc-sedo-4.png" alt="Sedo RSOC integration step 5"
                          sx={{mb: 1}}/>

                <p>
                    6. Give your Postback URL to your account manager.
                </p>
                <Alert color="warning" variant="filled" severity="warning" sx={{mt: 2, mb: 2}}>
                    {`Postback URL: https://skrotrack.com/postback?clickId=CLICK_ID&p1=KEYWORD`}
                </Alert>

                <DocH2>Step 4. Use Custom Conversions (OPTIONAL)</DocH2>
                <DocDivider/>
                <p>
                    With custom conversion events feature it is possible to track different events, revenue and clicks
                    on a different columns. For Predicto we are going to create event <strong>Purchase</strong>.
                </p>
                <p>1. Go back to Skro and navigate to the <strong>Settings</strong> -> <strong>Custom
                    Conversions</strong> and click on <strong>Create</strong> button.</p>
                <p>
                    2. Fill all the fields and click on <strong>Save & Close</strong> button.
                </p>
                <DocImage src="/images/docs/rsocsedo/rsoc-sedo-6.png" alt="Sedo RSOC event purchase"/>

                <p>
                    3. Give your Postback URL to your account manager.
                </p>
                <Alert color="warning" variant="filled" severity="warning" sx={{mt: 2, mb: 2}}>
                    {`Postback URL: https://skrotrack.com/postback?ce=Purchase&clickId=CLICK_ID&p1=KEYWORD`}
                </Alert>

                <DocDivider/>
                <p>Integration setup is done and your are ready to sync your earnings data.</p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default RsocSedoIntegration;
