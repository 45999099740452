import React, {useState} from "react";
import {
    Tabs,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography, Box, useTheme
} from "@mui/material";
import {Check, Clear} from "@mui/icons-material";

const plans = [
    {
        name: "Free",
        price: "€0/mo",
        optimization: {
            "Ads manager": true,
            "Turn on / off campaigns, ad sets and ads": true,
            "Change bidding for campaigns and ad sets": true,
            "Change daily budget for campaigns and ad sets": true,
            "External columns (Impressions, CTR, CPC etc.)": true
        },
        addOns: {
            "Extra user": false,
            "Extra 5 users": false,
            "Cost sync every 10 minutes (FB, TT)": false,
            "Custom cost/revenue integration": false
        },
        trackingAndAttribution: {
            "Number of events": "250 000",
            "Overage per 1000 events": "€0.05",
            "Total number of entries": "20",
            "S2S tracking": true,
            "Redirect tracking": true,
            "No-redirect tracking": true,
            "Direct / Organic traffic tracking": true,
            "First / Last-click attribution": true,
            "Last paid click attribution": true,
            "Custom conversion events": true,
            "S2S and CAPI postbacks": true,
            "Attribute conversions by click time": true,
            "Attribute conversions by conversion time": true,
            "Postback protection": true,
            "Custom domains with free SSL": "10",
            "Send postback %": true,
        },
        reports: {
            "Data retention": "3 months",
            "Performance reports": true,
            "3-Dimensional reports": true,
            "Tags": true,
            "Notes": true,
            "Custom columns": false,
            "Clicks, Conversions and Postback logs": true,
            "Export ( CSV / API)": true,
        },
        trafficDistribution: {
            "Pre-filled templates": true,
            "Weight-based distribution / rotation": true,
            "Smartlinks": true,
            "Shared flows": true,
            "Offer cap": true,
            "Geo filters": true,
            "Device filters": true,
            "Connection filters": true,
            "Referrer filters": true,
            "Custom column filters": true,
            "Time filters": true,
            "Bot detection": true,
            "Uniqueness 24h": true,
            "Redirect 302": true,
            "Redirect meta refresh": true,
            "Redirect double-meta refresh": true,
            "Landing protection script (PHP / JS)": true,
            "Cost models (Auto, CPC, CPA, CPM, CPS)": true,
        },
        integrations: {
            "Traffic source API integrations & templates": true,
            "Multiple ad accounts / BM support": false,
            "Affiliate network templates": true,
            "Conversion API": true,
            "Multiple pixels / Data Sources support": true,
            "Traffic source cost updates API": true,
            "30 min cost update frequency": "Ad level",
            "Cost sync integrations": "1",
            "Revenue Sync (Ads.com, Sedo, System1, Bodis, Tonic, Domain Active)": "1",
            "Revenue Sync RSOC (System1, Ads.com, Inuvo, AMG, Ask Media Group, Predicto.ai)": "1",
            "Deploy static websites on AWS & Issue SSL certs": false,
            "AWS integration": false,
            "Domain provider API integration (Cloudflare, Namesilo)": false,
        },
        team: {
            "Master / Private / Public Workspaces": true,
            "Multiple users": false,
            "API access to reports": true,
        }
    },
    {
        name: "Starter",
        price: "€39/mo",
        optimization: {
            "Ads manager": true,
            "Turn on / off campaigns, ad sets and ads": true,
            "Change bidding for campaigns and ad sets": true,
            "Change daily budget for campaigns and ad sets": true,
            "External columns (Impressions, CTR, CPC etc.)": true
        },
        addOns: {
            "Extra user": false,
            "Extra 5 users": false,
            "Cost sync every 10 minutes (FB, TT)": false,
            "Custom cost/revenue integration": false
        },
        trackingAndAttribution: {
            "Number of events": "2 500 000",
            "Overage per 1000 events": "€0.03",
            "Total number of entries": "Unlimited",
            "S2S tracking": true,
            "Redirect tracking": true,
            "No-redirect tracking": true,
            "Direct / Organic traffic tracking": true,
            "First / Last-click attribution": true,
            "Last paid click attribution": true,
            "Custom conversion events": true,
            "S2S and CAPI postbacks": true,
            "Attribute conversions by click time": true,
            "Attribute conversions by conversion time": true,
            "Postback protection": true,
            "Custom domains with free SSL": "50",
            "Send postback %": true,
        },
        reports: {
            "Data retention": "6 months",
            "Performance reports": true,
            "3-Dimensional reports": true,
            "Tags": true,
            "Notes": true,
            "Custom columns": "5",
            "Clicks, Conversions and Postback logs": true,
            "Export ( CSV / API)": true,
        },
        trafficDistribution: {
            "Pre-filled templates": true,
            "Weight-based distribution / rotation": true,
            "Smartlinks": true,
            "Shared flows": true,
            "Offer cap": true,
            "Geo filters": true,
            "Device filters": true,
            "Connection filters": true,
            "Referrer filters": true,
            "Custom column filters": true,
            "Time filters": true,
            "Bot detection": true,
            "Uniqueness 24h": true,
            "Redirect 302": true,
            "Redirect meta refresh": true,
            "Redirect double-meta refresh": true,
            "Landing protection script (PHP / JS)": true,
            "Cost models (Auto, CPC, CPA, CPM, CPS)": true,
        },
        integrations: {
            "Traffic source API integrations & templates": true,
            "Multiple ad accounts / BM support": true,
            "Affiliate network templates": true,
            "Conversion API": true,
            "Multiple pixels / Data Sources support": true,
            "Traffic source cost updates API": true,
            "30 min cost update frequency": "Ad level",
            "Cost sync integrations": "3",
            "Revenue Sync (Ads.com, Sedo, System1, Bodis, Tonic, Domain Active)": "3",
            "Revenue Sync RSOC (System1, Ads.com, Inuvo, AMG, Ask Media Group, Predicto.ai)": "3",
            "Deploy static websites on AWS & Issue SSL certs": "3",
            "AWS integration": true,
            "Domain provider API integration (Cloudflare, Namesilo)": false,
        },
        team: {
            "Master / Private / Public Workspaces": true,
            "Multiple users": false,
            "API access to reports": true,
        }
    },
    {
        name: "Pro",
        price: "€99/mo",
        optimization: {
            "Ads manager": true,
            "Turn on / off campaigns, ad sets and ads": true,
            "Change bidding for campaigns and ad sets": true,
            "Change daily budget for campaigns and ad sets": true,
            "External columns (Impressions, CTR, CPC etc.)": true
        },
        addOns: {
            "Extra user": false,
            "Extra 5 users": false,
            "Cost sync every 10 minutes (FB, TT)": "150€",
            "Custom cost/revenue integration": "500€"
        },
        trackingAndAttribution: {
            "Number of events": "5 000 000",
            "Overage per 1000 events": "€0.02",
            "Total number of entries": "Unlimited",
            "S2S tracking": true,
            "Redirect tracking": true,
            "No-redirect tracking": true,
            "Direct / Organic traffic tracking": true,
            "First / Last-click attribution": true,
            "Last paid click attribution": true,
            "Custom conversion events": true,
            "S2S and CAPI postbacks": true,
            "Attribute conversions by click time": true,
            "Attribute conversions by conversion time": true,
            "Postback protection": true,
            "Custom domains with free SSL": "100",
            "Send postback %": true,
        },
        reports: {
            "Data retention": "12 months",
            "Performance reports": true,
            "3-Dimensional reports": true,
            "Tags": true,
            "Notes": true,
            "Custom columns": "10",
            "Clicks, Conversions and Postback logs": true,
            "Export ( CSV / API)": true,
        },
        trafficDistribution: {
            "Pre-filled templates": true,
            "Weight-based distribution / rotation": true,
            "Smartlinks": true,
            "Shared flows": true,
            "Offer cap": true,
            "Geo filters": true,
            "Device filters": true,
            "Connection filters": true,
            "Referrer filters": true,
            "Custom column filters": true,
            "Time filters": true,
            "Bot detection": true,
            "Uniqueness 24h": true,
            "Redirect 302": true,
            "Redirect meta refresh": true,
            "Redirect double-meta refresh": true,
            "Landing protection script (PHP / JS)": true,
            "Cost models (Auto, CPC, CPA, CPM, CPS)": true,
        },
        integrations: {
            "Traffic source API integrations & templates": true,
            "Multiple ad accounts / BM support": true,
            "Affiliate network templates": true,
            "Conversion API": true,
            "Multiple pixels / Data Sources support": true,
            "Traffic source cost updates API": true,
            "30 min cost update frequency": "Ad level",
            "Cost sync integrations": "Unlimited",
            "Revenue Sync (Ads.com, Sedo, System1, Bodis, Tonic, Domain Active)": "Unlimited",
            "Revenue Sync RSOC (System1, Ads.com, Inuvo, AMG, Ask Media Group, Predicto.ai)": "Unlimited",
            "Deploy static websites on AWS & Issue SSL certs": "25",
            "AWS integration": true,
            "Domain provider API integration (Cloudflare, Namesilo)": true,
        },
        team: {
            "Master / Private / Public Workspaces": true,
            "Multiple users": false,
            "API access to reports": true,
        }
    },
    {
        name: "Team",
        price: "€199/mo",
        optimization: {
            "Ads manager": true,
            "Turn on / off campaigns, ad sets and ads": true,
            "Change bidding for campaigns and ad sets": true,
            "Change daily budget for campaigns and ad sets": true,
            "External columns (Impressions, CTR, CPC etc.)": true
        },
        addOns: {
            "Extra user": "25€",
            "Extra 5 users": false,
            "Cost sync every 10 minutes (FB, TT)": "150€",
            "Custom cost/revenue integration": "500€"
        },
        trackingAndAttribution: {
            "Number of events": "10 000 000",
            "Overage per 1000 events": "€0.02",
            "Total number of entries": "Unlimited",
            "S2S tracking": true,
            "Redirect tracking": true,
            "No-redirect tracking": true,
            "Direct / Organic traffic tracking": true,
            "First / Last-click attribution": true,
            "Last paid click attribution": true,
            "Custom conversion events": true,
            "S2S and CAPI postbacks": true,
            "Attribute conversions by click time": true,
            "Attribute conversions by conversion time": true,
            "Postback protection": true,
            "Custom domains with free SSL": "150",
            "Send postback %": true,
        },
        reports: {
            "Data retention": "12 months",
            "Performance reports": true,
            "3-Dimensional reports": true,
            "Tags": true,
            "Notes": true,
            "Custom columns": "20",
            "Clicks, Conversions and Postback logs": true,
            "Export ( CSV / API)": true,
        },
        trafficDistribution: {
            "Pre-filled templates": true,
            "Weight-based distribution / rotation": true,
            "Smartlinks": true,
            "Shared flows": true,
            "Offer cap": true,
            "Geo filters": true,
            "Device filters": true,
            "Connection filters": true,
            "Referrer filters": true,
            "Custom column filters": true,
            "Time filters": true,
            "Bot detection": true,
            "Uniqueness 24h": true,
            "Redirect 302": true,
            "Redirect meta refresh": true,
            "Redirect double-meta refresh": true,
            "Landing protection script (PHP / JS)": true,
            "Cost models (Auto, CPC, CPA, CPM, CPS)": true,
        },
        integrations: {
            "Traffic source API integrations & templates": true,
            "Multiple ad accounts / BM support": true,
            "Affiliate network templates": true,
            "Conversion API": true,
            "Multiple pixels / Data Sources support": true,
            "Traffic source cost updates API": true,
            "30 min cost update frequency": "Ad level",
            "Cost sync integrations": "Unlimited",
            "Revenue Sync (Ads.com, Sedo, System1, Bodis, Tonic, Domain Active)": "Unlimited",
            "Revenue Sync RSOC (System1, Ads.com, Inuvo, AMG, Ask Media Group, Predicto.ai)": "Unlimited",
            "Deploy static websites on AWS & Issue SSL certs": "50",
            "AWS integration": true,
            "Domain provider API integration (Cloudflare, Namesilo)": true,
        },
        team: {
            "Master / Private / Public Workspaces": true,
            "Multiple users": "5",
            "API access to reports": true,
        }
    },
    {
        name: "Enterprise",
        price: "€449/mo",
        optimization: {
            "Ads manager": true,
            "Turn on / off campaigns, ad sets and ads": true,
            "Change bidding for campaigns and ad sets": true,
            "Change daily budget for campaigns and ad sets": true,
            "External columns (Impressions, CTR, CPC etc.)": true
        },
        addOns: {
            "Extra user": "25€",
            "Extra 5 users": "100€",
            "Cost sync every 10 minutes (FB, TT)": "150€",
            "Custom cost/revenue integration": "500€"
        },
        trackingAndAttribution: {
            "Number of events": "30 000 000",
            "Overage per 1000 events": "€0.01",
            "Total number of entries": "Unlimited",
            "S2S tracking": true,
            "Redirect tracking": true,
            "No-redirect tracking": true,
            "Direct / Organic traffic tracking": true,
            "First / Last-click attribution": true,
            "Last paid click attribution": true,
            "Custom conversion events": true,
            "S2S and CAPI postbacks": true,
            "Attribute conversions by click time": true,
            "Attribute conversions by conversion time": true,
            "Postback protection": true,
            "Custom domains with free SSL": "300",
            "Send postback %": true,
        },
        reports: {
            "Data retention": "24 months",
            "Performance reports": true,
            "3-Dimensional reports": true,
            "Tags": true,
            "Notes": true,
            "Custom columns": "20",
            "Clicks, Conversions and Postback logs": true,
            "Export ( CSV / API)": true,
        },
        trafficDistribution: {
            "Pre-filled templates": true,
            "Weight-based distribution / rotation": true,
            "Smartlinks": true,
            "Shared flows": true,
            "Offer cap": true,
            "Geo filters": true,
            "Device filters": true,
            "Connection filters": true,
            "Referrer filters": true,
            "Custom column filters": true,
            "Time filters": true,
            "Bot detection": true,
            "Uniqueness 24h": true,
            "Redirect 302": true,
            "Redirect meta refresh": true,
            "Redirect double-meta refresh": true,
            "Landing protection script (PHP / JS)": true,
            "Cost models (Auto, CPC, CPA, CPM, CPS)": true,
        },
        integrations: {
            "Traffic source API integrations & templates": true,
            "Multiple ad accounts / BM support": true,
            "Affiliate network templates": true,
            "Conversion API": true,
            "Multiple pixels / Data Sources support": true,
            "Traffic source cost updates API": true,
            "30 min cost update frequency": "Ad level",
            "Cost sync integrations": "Unlimited",
            "Revenue Sync (Ads.com, Sedo, System1, Bodis, Tonic, Domain Active)": "Unlimited",
            "Revenue Sync RSOC (System1, Ads.com, Inuvo, AMG, Ask Media Group, Predicto.ai)": "Unlimited",
            "Deploy static websites on AWS & Issue SSL certs": "Unlimited",
            "AWS integration": true,
            "Domain provider API integration (Cloudflare, Namesilo)": true,
        },
        team: {
            "Master / Private / Public Workspaces": true,
            "Multiple users": "10",
            "API access to reports": true,
        }
    },
];

const sections = ["optimization", "addOns", "trackingAndAttribution", "reports", "trafficDistribution", "integrations", "team"];

export default function PricingTable() {
    const theme = useTheme();
    const [selectedSection, setSelectedSection] = useState("optimization");
    return (
        <Paper elevation={0}>

            <Typography variant="h3" align="center" sx={{paddingBottom: "50px", pt: "50px"}}>
                Compare plans
            </Typography>

            <Tabs
                value={selectedSection}
                onChange={(e, newValue) => setSelectedSection(newValue)}
                indicatorColor="primary"
                textColor="primary"
                centered
                variant="scrollable"
                scrollButtons="auto"
                sx={{
                    "& .MuiTabs-indicator": { display: "none" }, // Remove bottom border indicator
                    mb: 6,
                    "& .MuiTabs-flexContainer": {
                        justifyContent: "center"
                    },
                    "&.Mui-selected": {
                        backgroundColor: "background.defaultBlue",
                        borderBottomWidth: "2px",
                        borderBottomStyle: "solid",
                        borderBottomColor: "red",
                        marginBottom: "-2px",
                        ":hover": {
                            backgroundColor: "background.rowHover"
                        }
                    },
                    [theme.breakpoints.down(800)]: {
                        "& .MuiTabs-flexContainer": {
                            justifyContent: "left"
                        }
                    }

                }}
            >
                {sections.map(section => (
                    <Tab
                        key={section}
                        value={section}
                        label={section === "addOns" ? "ADD-ONS" : section.replace(/([A-Z])/g, " $1").trim().toUpperCase()}
                        sx={{
                            backgroundColor: theme.palette.common.darkBlue,
                            color: theme.palette.common.white,
                            borderRadius: "24px", // Rounded corners
                            mx: 1, // Margin between tabs
                            px: 2, // Padding inside tabs
                            "&.Mui-selected": {
                                backgroundColor: theme.palette.common.darkBlue,
                            },
                        }}
                    />
                ))}
            </Tabs>

            <TableContainer component={Paper} elevation={0}>
                <Table>
                    <TableHead sx={{borderBottomColor: theme.border.borderLight}}>
                        <TableRow>
                            <TableCell
                                sx={{
                                    fontSize: "18px",
                                    color: theme.palette.common.defaultBlue,
                                    borderBottomColor: theme.border.borderLight
                                }}>
                                Feature
                            </TableCell>
                            {plans.map(plan => (
                                <TableCell sx={{
                                    fontSize: "18px",
                                    color: theme.palette.common.defaultBlue,
                                    borderBottomColor: theme.border.borderLight
                                }} key={plan.name}>
                                    {plan.name}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(plans[0][selectedSection]).map((feature, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{
                                    fontSize: "16px",
                                    color: theme.palette.common.grayLight,
                                    p: 2,
                                    borderBottomColor: theme.border.borderLight,
                                    minWidth: 250,
                                }}>{feature}</TableCell>
                                {plans.map(plan => (
                                    <TableCell sx={{
                                        fontSize: "16px",
                                        color: theme.palette.common.grayLight,
                                        borderBottomColor: theme.border.borderLight,
                                        minWidth: 130,
                                    }} key={plan.name}>
                                        {typeof plan[selectedSection][feature] === "boolean" ? (
                                            plan[selectedSection][feature] ? <Check color="success" fontSize="small"/> :
                                                <Clear color="error" fontSize="small"/>
                                        ) : (
                                            <Box sx={{fontSize: "16px", color: theme.palette.common.grayLight}}>
                                                {plan[selectedSection][feature]}
                                            </Box>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}
