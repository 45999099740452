import React, {Fragment} from "react";
import {Box, Button, Card, Grid, Typography, useTheme, useMediaQuery} from "@mui/material";
import WaveBorder from "../../shared/components/WaveBorder";
import {ContainerFluid} from "./Home";

function HeadSection() {
    const theme = useTheme();
    const onlyLargeScreen = useMediaQuery(theme.breakpoints.down("md"));
    return (
        <Fragment>
            <Box sx={{
                paddingTop: 8,
                position: "relative",
                backgroundColor: "common.defaultBlue",
                paddingBottom: 2,
                [theme.breakpoints.down("sm")]: {
                    paddingTop: "70px !important",
                },
            }}>
                <ContainerFluid>
                    <Box display="flex" justifyContent="center" className="row">
                        <Card
                            sx={{
                                backgroundColor: "transparent",
                                color: "common.white",
                                boxShadow: 0,
                                marginLeft: 2,
                                marginRight: 2,
                                [theme.breakpoints.up("xs")]: {
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                },
                                [theme.breakpoints.up("sm")]: {
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    paddingLeft: 4,
                                    paddingRight: 4,
                                },
                                [theme.breakpoints.up("md")]: {
                                    paddingTop: 5.5,
                                    paddingBottom: 5.5,
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                },
                                [theme.breakpoints.up("lg")]: {
                                    paddingTop: 6,
                                    paddingBottom: 6,
                                    paddingLeft: 6,
                                    paddingRight: 6,
                                },
                                [theme.breakpoints.down("lg")]: {
                                    width: "auto",
                                },
                            }}
                            data-aos-delay="200"
                            data-aos="zoom-in"
                        >
                            <Box sx={{
                                [theme.breakpoints.up("md")]: {
                                    maxWidth: "none !important",
                                },
                            }}>
                                <Box justifyContent="space-between" className="row">
                                    <Grid item xs={12}>
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="space-between"
                                            height="100%"
                                        >
                                            <Box mb={4}>
                                                <Typography
                                                    variant="h1"
                                                    sx={{
                                                        ...(!onlyLargeScreen && {
                                                            fontWeight: 600,
                                                            fontSize: "46px !important",
                                                        }),
                                                        ...(onlyLargeScreen && {
                                                            fontWeight: 500,
                                                            fontSize: "30px !important",
                                                            width: "100%",
                                                        }),
                                                        textAlign: "center",
                                                        width: "100%",
                                                        marginLeft: "auto",
                                                        marginRight: "auto",
                                                    }}
                                                >
                                                    Ad Tracking Solution For All Your Advertising Needs
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Box mb={2}>
                                                    <Typography
                                                        variant="h2"
                                                        sx={{
                                                            ...(!onlyLargeScreen && {
                                                                fontSize: "24px !important",
                                                            }),
                                                            ...(onlyLargeScreen && {
                                                                fontSize: "20px !important",
                                                                mb: 5
                                                            }),
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        Track, optimize and scale your ad campaigns with
                                                        one dashboard.
                                                    </Typography>
                                                </Box>
                                                <Box sx={{textAlign: "center"}}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth={true}
                                                        sx={{
                                                            fontSize: 18,
                                                            [theme.breakpoints.up("md")]: {
                                                                justifyContent: 'center',
                                                                maxWidth: 300,
                                                                marginTop: theme.spacing(2),
                                                                marginBottom: theme.spacing(2),
                                                                paddingTop: "12px",
                                                                paddingBottom: "12px",
                                                            }
                                                        }}
                                                        href="https://app.skro.eu/login"
                                                    >
                                                        Try for free
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        md={12}
                                        sx={{
                                            ...(!onlyLargeScreen && {
                                                paddingTop: "18px"
                                            }),
                                            ...(onlyLargeScreen && {
                                                paddingTop: 5
                                            }),
                                        }}
                                    >
                                        <img style={{
                                            maxWidth: "100%",
                                            verticalAlign: "middle",
                                            borderRadius: 4,
                                            boxShadow: 6,
                                            border: "1px solid #000"
                                        }}
                                             src="/images/skro-app.svg" alt="Skro Tracker"
                                        />
                                    </Grid>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                </ContainerFluid>
            </Box>
            <WaveBorder
                upperColor={theme.palette.common.defaultBlue}
                lowerColor="#FFFFFF"
                animationnegativedelay={4}
            />
        </Fragment>
    );
}

export default HeadSection;
